@import 'function/var';

// MARK:練習謎
.exercise {
  &__section {}

  &__navlist {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(225px, 100%), 1fr));
    gap: 32px 24px;

    @include minmq($md) {
      gap: 80px 24px;
    }
  }

  &__navitem {
    position: relative;

    &--cardlink {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr 40px;
      gap: 0;
      justify-items: center;
      align-content: space-between;
      height: 100%;
      background-color: var(--color-btn-secondary);
      border: 2px solid var(--color-btn-line);
      border-radius: var(--border-radius);
      box-shadow: var(--shadow-btn-default);
      overflow: hidden;

      @include hover {
        .exercise__navitem--cardmore {
          background-color: var(--color-nazored);
          color: var(--color-white);
        }
      }
    }

    &--cardtitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 20px;
      padding-left: 72px;

      .is-genre {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -16px;
        left: 22px;
      }

      .is-label {
        color: var(--color-gray3);
        @include fontsize(24);
        font-weight: 500;
        line-height: 1.4;
        text-align: center;
      }
    }

    &--cardthumbnail {
      margin-bottom: 0;
    }

    &--cardmore {
      display: grid;
      place-items: center;
      width: 100%;
      @include fontsize(18);
      font-family: var(--font-satoshi);
      font-weight: 900;
      line-height: 1.4;
      text-align: center;
      color: var(--color-keynavy);
      background-color: #E1E4EA;
      transition: background-color .3s var(--easing-cubic1), color .3s var(--easing-cubic1);
    }
  }

  &__block {
    padding: 24px 24px 32px;
    background-color: var(--color-bgbox);
    border-radius: var(--border-radius);

    @include minmq($lg) {
      padding: 56px 80px 80px;
    }

    .title__style1 {
      position: relative;
      margin-left: 72px;

      .is-genre {
        max-width: 56px;
        position: absolute;
        top: 0;
        left: -72px;
      }

      .title__label {
        translate: 0 3px;
      }

      @include minmq($lg) {
        margin-left: 102px;

        .is-genre {
          max-width: 86px;
          left: -102px;
        }

        .title__label {
          translate: 0 6px;
        }
      }
    }

    &--img {
      display: flex;
      justify-content: center;
      background-color: var(--color-white);
      border-radius: var(--border-radius);
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
      }
    }

    &--result {
      margin-bottom: 32px;

      @include minmq($lg) {
        margin-bottom: 56px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: var(--border-radius);
      }
    }

    &--answer {
      @include fontsize(24);
      font-weight: 500;
      line-height: 1.4;
      margin-bottom: 16px;
      text-align: center;

      @include minmq($lg) {
        margin-bottom: 24px;
      }
    }

    &--input {
      margin-top: 32px;

      @include minmq($lg) {
        margin-top: 56px;
      }
    }

    &--form {
      display: grid;
      grid-template-columns: 1fr;
      gap: 8px;

      @include minmq($md) {
        grid-template-columns: 1fr auto;
      }

      .input-text {
        width: 100%;
        @include fontsize(16);
        font-weight: 400;
        padding: 14px 16px;
        background-color: var(--color-white);
        border: 1px solid var(--color-gray1);
        border-radius: 10px;

        &::placeholder {
          color: var(--color-gray3);
        }

        @include minmq($xs) {
          padding: 14px 24px;
        }
      }

      .input-submit {
        width: 100%;
        text-align: center;
        color: var(--color-white);
        @include fontsize(16);
        font-weight: 500;
        padding: 8px 24px;
        background-color: var(--color-gray1);
        border: 1px solid var(--color-gray1);
        border-radius: 10px;
        box-shadow: var(--shadow-btn-default);
        transition: box-shadow .15s var(--easing-cubic1), translate .15s var(--easing-cubic1);

        @include minmq($md) {
          max-width: 84px;
        }

        @include hover {
          translate: 0 2px;
          box-shadow: 0 4px 0 rgba(23 27 60 / .13);
        }
      }
    }

    &--share {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      margin-top: 32px;

      .is-label {
        @include fontsize(16);
        font-weight: 500;
        text-align: center;
      }

      .is-snslink {
        display: flex;
        justify-content: center;
        gap: 6px;
      }

      @include minmq($md) {
        margin-top: 56px;
        flex-direction: row;
        justify-content: flex-end;

        .is-label {
          text-align: right;
        }
      }
    }

    &--back {
      display: flex;
      justify-content: center;
      margin-top: 32px;

      .btn__secondary {
        justify-content: center;
      }

      @include minmq($md) {
        margin-top: 56px;
      }
    }

  }


  .pagenav {
    margin-top: 56px;

    @include minmq($lg) {
      margin-top: 120px;
    }

    &__slider {
      &--card {
        padding: 0;
      }

      &--thumbnail {
        gap: 24px;
      }

      &--posttitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px 24px;

        @include minmq($xs) {
          padding: 0 16px 32px;
        }

        .is-genre {
          position: absolute;
          left: 12px;
          bottom: 10px;
          z-index: 1;

          @include minmq($xs) {
            left: 16px;
            bottom: 18px;
          }

          @include minmq($sm) {
            left: 22px;
          }
        }

        .is-label {
          flex: 0 1 100%;
          text-align: center;
        }
      }
    }

  }

}

// MARK:無料お試し受検コーナー
.pre-test {
  &__section {

    &+& {
      margin-top: 56px;

      @include minmq($lg) {
        margin-top: 120px;
      }
    }

    .btn__primary {
      &[target="_blank"] {
        &::after {
          display: block;
          content: "";
          width: 16px;
          height: 16px;
          mask-image: url('/assets/img/common/icon-blank.svg');
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
          background: var(--color-white);
          position: absolute;
          top: 52%;
          right: 20px;
          translate: 0 -50%;
        }
      }

      @include hover {
        &[target="_blank"] {
          &::after {
            translate: 0 -50%;
          }
        }
      }
    }
  }

}


// MARK:いつでも謎検
.itsudemo {
  .btnbox {
    @include minmq($md) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}


// MARK:受検対策グッズ
.reference {
  &__section {
    &+& {
      margin-top: 56px;

      @include minmq($lg) {
        margin-top: 120px;
      }
    }

    .is-mgn {
      margin-top: 4px;
    }
  }

  &__text {
    @include fontsize(18);
    font-weight: 400;
    line-height: 2;
    margin-bottom: 56px;

    @include minmq($lg) {
      margin-bottom: 56px;
    }
  }

  .btnbox {
    margin-top: 80px;

    @include minmq($lg) {
      margin-top: 120px;
    }
  }

  &__box {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'itemimg'
      'itemconts'
      'itembtn';
    gap: 40px;
    padding: 32px 24px 40px;
    background-color: var(--color-bgbox);
    border-radius: var(--border-radius);

    &+& {
      margin-top: 32px;
    }

    @include minmq($md) {
      gap: 56px clamp-px(56px, 64px, 80px, $lg);
      padding: 80px;
      grid-template-columns: 280px 1fr;
      grid-template-areas:
        'itemimg itemconts'
        'itembtn itembtn';

      &+& {
        margin-top: 80px;
      }
    }

    &--img {
      display: flex;
      justify-content: center;
      grid-area: itemimg;

      img {
        max-width: 200px;
      }

      @include minmq($md) {
        img {
          max-width: 100%;
        }
      }
    }

    &--itemname {
      @include kerning(30);
      font-size: clamp-px(20px, 22px, 24px, $lg);
      font-weight: 600;
      line-height: 1.6;
    }

    &--iteminfo {
      margin-top: 24px;

      @include minmq($md) {
        margin-top: 32px;
      }

      li {
        color: var(--color-gray3);
        @include fontsize(14);
        font-weight: 400;
        line-height: 1.6;
      }
    }

    &--conts {
      grid-area: itemconts;
    }

    &--block {
      margin-top: 16px;
      @include fontsize(16);
      font-weight: 400;
      line-height: 2;
      transition: height .3s var(--easing-cubic1);
      overflow: hidden;

      // &.is-open {}

      @include minmq($md) {
        display: block;
        overflow: visible;
        height: auto !important;
      }
    }

    &--itemcopy {
      margin-top: 24px;
      font-size: clamp-px(16px, 18px, 18px, $lg);
      font-weight: 600;
      line-height: 1.8;

      @include minmq($md) {
        margin-top: 32px;
      }
    }

    &--itemtxt {
      @include fontsize(16);
      font-weight: 400;
      line-height: 2;

      &+& {
        margin-top: 16px;
      }
    }

    &--btn {
      text-align: right;
      margin-top: 16px;
    }

    &--trigger {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      color: var(--color-txtlink);
      @include fontsize(14);
      font-weight: 500;
      background: none;

      &::after {
        flex: 0 0 auto;
        display: block;
        content: "";
        width: 7px;
        height: 4px;
        mask-image: url('/assets/img/common/icon-arrow2_bottom.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: var(--color-keynavy);
        rotate: 0deg;
        transition: background-color .3s var(--easing-cubic1), rotate .3s var(--easing-cubic1);
      }

      &[aria-expanded="true"] {
        &::after {
          rotate: -180deg;
        }
      }
    }

    &--buy {
      grid-area: itembtn;
      display: flex;
      justify-content: center;

      .btn__secondary {
        justify-content: center;
      }
    }
  }

}