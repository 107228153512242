@import 'function/var';

.gnav {
  max-width: 375px;
  width: 100%;
  height: 100%;
  height: 100dvh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 15;
  pointer-events: none;
  translate: 101% 0;
  transition: translate .3s var(--easing-cubic1);

  @include minmq($lg) {
    max-width: 640px;
  }

  &__close {
    flex: 0 0 auto;
    width: var(--header-height);
    height: var(--header-height);
    background-color: var(--color-bgnav);
    position: fixed;
    top: 0;
    right: 0;

    span,
    &::before,
    &::after {
      display: block;
      content: '';
      width: 26px;
      height: 0;
      border-radius: var(--border-halfcircle);
      border-top: 2px solid var(--color-white);
      position: absolute;
      top: 50%;
      left: 50%;
      pointer-events: none;
      transition: opacity .3s var(--easing-cubic1), translate .3s var(--easing-cubic1), rotate .3s var(--easing-cubic1);

      @include minmq($lg) {
        width: 34px;
      }

    }

    span {
      opacity: 0;
      translate: 0 -50%;
    }

    &::before {
      width: 28px;
      rotate: 45deg;
      translate: -50% -50%;
    }

    &::after {
      width: 28px;
      rotate: -45deg;
      translate: -50% -50%;
    }
  }

  .is-gnavshow & {
    translate: 0 0;
    pointer-events: all;
    transition: translate .3s var(--easing-cubic1);
  }

  &__container {
    display: block;
    width: 100%;
    padding: var(--header-height) 16px 40px;
    background-color: var(--color-bgnav);
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

    @include minmq($xs) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @include minmq($lg) {
      display: grid;
      gap: 56px;
      justify-content: space-between;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
      padding: 120px 80px 80px;
      border-bottom-right-radius: 0;
    }
  }

  &__list {
    border-top: 1px solid var(--color-gray4);

    @include minmq($lg) {
      border-top: none;

      &+& {
        margin-top: 56px;
      }
    }

    &.is-about {
      grid-area: about;
    }

    &.is-challenge {
      grid-area: challenge;
    }

    &.is-practice {
      grid-area: practice;
    }

    &--label {
      background: none;
    }

    &--block {
      display: none;

      &[aria-hidden="false"] {
        display: block;
        animation: inviewdown .4s var(--easing-cubic1) forwards;
      }

      @include minmq($lg) {
        display: block;
      }
    }

    &--trigger {
      width: 100%;
      text-align: left;
      padding: 16px 40px 16px 0;
      color: var(--color-white);
      font-size: clamp-px(16px, 18px, 18px, $lg);
      background: none;
      position: relative;
      transition: color .3s var(--easing-cubic1);

      @include minmq($lg) {
        padding: 0;
        color: var(--color-gray3);
        cursor: auto;
        pointer-events: none;
      }

      &::before,
      &::after {
        display: inline-block;
        content: '';
        width: 14px;
        height: 0;
        border-top: 2px solid var(--color-white);
        border-radius: var(--border-halfcircle);
        position: absolute;
        top: 50%;
        right: 0;
        translate: 0 -50%;
        transition: rotate .3s var(--easing-cubic1);

        @include minmq($lg) {
          display: none;
        }
      }

      &::after {
        rotate: 90deg;
      }

      &[aria-selected="true"] {
        color: var(--color-gray3);

        &::after {
          rotate: 0deg;
        }
      }
    }
  }

  &__linklist {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    padding-bottom: 16px;

    @include minmq($lg) {
      padding-top: 16px;
      padding-bottom: 0;
    }
  }

  &__linkitem {
    position: relative;

    .is-small {
      @include fontsize(12);
    }

    a {
      display: block;
      width: 100%;
      padding-right: 24px;
      color: var(--color-white);
      @include fontsize(14);
      font-weight: 400;

      &::after {
        display: block;
        content: '';
        width: 4px;
        height: 7px;
        mask-image: url('/assets/img/common/icon-arrow2_right.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background-color: var(--color-white);
        position: absolute;
        top: 50%;
        right: 6px;
        translate: 0 -50%;
        transition: translate .3s var(--easing-cubic1);
      }

      @include hover {
        &::after {
          translate: 6px -50%;
        }
      }
    }
  }

  &__aside {
    display: grid;
    grid-template-areas:
      'social'
      'submenu'
      'mypage';
    grid-template-columns: 1fr;

    @include minmq($lg) {
      height: 100%;
      align-items: flex-start;
      grid-template-rows: auto auto 1fr;
      grid-template-areas:
        'mypage'
        'submenu'
        'social';
      background: url('/assets/img/common/nazoken-logo_white.svg') center bottom no-repeat;
    }
  }

  &__submenu {
    grid-area: submenu;
    display: grid;
    gap: 18px 16px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    margin-top: 32px;

    @include minmq($lg) {
      gap: 24px;
      grid-template-columns: 1fr;
      margin-top: 80px;
    }

    &--item {
      position: relative;

      a {
        display: block;
        width: 100%;
        padding-right: 24px;
        color: var(--color-white);
        @include fontsize(14);
        font-weight: 500;

        &::after {
          display: block;
          content: '';
          width: 16px;
          height: 16px;
          mask-image: url('/assets/img/common/icon-arrow1_right.svg');
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
          background-color: var(--color-white);
          position: absolute;
          top: 50%;
          right: 6px;
          translate: 0 -50%;
          transition: translate .3s var(--easing-cubic1);
        }

        @include hover {
          &::after {
            translate: 6px -50%;
          }
        }
      }
    }
  }

  &__snslist {
    grid-area: social;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid var(--color-gray4);
    border-bottom: 1px solid var(--color-gray4);

    @include minmq($lg) {
      margin-top: 24px;
    }

    &--label {
      @include fontsize(12);
      line-height: 1.4;
      color: var(--color-gray3);
    }

    &--block {
      display: flex;
      gap: 6px;
    }
  }

  &__mypagelink {
    grid-area: mypage;
    margin-top: 32px;

    @include minmq($lg) {
      margin-top: 0;
    }

    &--btn {
      padding: 12px 16px;
      @include fontsize(15);
      width: 100%;
    }
  }
}