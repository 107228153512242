@import 'function/var';

.pagetitle {
  padding: 32px 16px;
  background-color: var(--color-keynavy);
  position: relative;

  @include minmq($xs) {
    padding: 24px;
  }

  @include minmq($lg) {
    padding: 32px 24px;
  }

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('/assets/img/common/bg-texture1.png');
    opacity: .25;
    z-index: 0;
    pointer-events: none;
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 4px 24px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    @include minmq($sm) {
      flex-direction: row;
    }

    .is-mainlabel {
      font-family: var(--font-satoshi);
      @include fontsize(45);
      @include kerning(30);
      font-weight: 900;
      line-height: 1.2;
      color: var(--color-white);
      text-transform: uppercase;

      @include minmq($xs) {
        // font-size: clamp-px(48px, 64px, 80px, $lg);
        font-size: clamp-px(48px, 56px, 64px, $lg);
      }

      &::first-letter {
        color: var(--color-nazored);
      }
    }

    .is-sublabel {
      font-size: clamp-px(14px, 15px, 16px, $lg);
      font-weight: 500;
      line-height: 1.4;
      color: var(--color-white);
    }

  }
}

.headgroup {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: clamp-px(12px, 14px, 16px, $lg);
  margin-bottom: 40px;

  &::before {
    flex: 0 0 auto;
    display: block;
    content: '';
    width: clamp-px(14px, 15px, 16px, $lg);
    height: clamp-px(14px, 15px, 16px, $lg);
    background-color: var(--color-nazored);
    border-radius: var(--border-circle);
    translate: 0 16px;
  }

  @include minmq($lg) {
    margin-bottom: 56px;

    &::before {
      translate: 0 20px;
    }
  }

  &__label {
    color: var(--color-keynavy);
    @include fontsize(29);
    font-weight: 800;
    line-height: 1.4;

    @include minmq($xs) {
      font-size: clamp-px(32px, 36px, 40px, $lg);
    }
  }

  .news__cat & {
    @include minmq($lg) {
      max-width: 880px;
      margin-left: auto;
    }
  }
}

.title {
  $label: '';

  &__label {
    font-weight: 500;
    line-height: 1.6;
    color: var(--color-gray1);
    $label: &;
  }

  &__style1 {
    padding-bottom: 16px;
    margin-bottom: 32px;
    border-bottom: 1px solid var(--color-gray2);
    position: relative;

    &::after {
      display: block;
      content: '';
      width: 20px;
      height: 0;
      border-bottom: 1px solid var(--color-nazored);
      position: absolute;
      left: 0;
      bottom: -1px;
      z-index: 1;
    }

    @include minmq($lg) {
      padding-bottom: 32px;
      margin-bottom: 56px;
    }

    #{$label} {
      font-size: clamp-px(28px, 30px, 32px, $lg);
    }

    &.is-noline {
      padding-bottom: 0;
      border-bottom: none;

      &::after {
        display: none;
      }

      #{$label} {
        color: var(--color-keynavy);
        font-size: clamp-px(28px, 34px, 40px, $lg);
      }
    }
  }

  &__style2 {
    margin-bottom: 32px;

    // @include minmq($lg) {
    //   margin-bottom: 32px;
    // }

    #{$label} {
      font-size: clamp-px(24px, 26px, 28px, $lg);
      line-height: 1.5;
    }
  }

  &__style3 {
    margin-bottom: 24px;

    #{$label} {
      @include kerning(30);
      font-size: clamp-px(20px, 22px, 24px, $lg);
      line-height: 1.6;
    }
  }


}