@import 'function/var';

.aside {
  &__nav {
    display: flex;
    justify-content: center;
    gap: 24px;
    width: 100%;
    overflow-x: auto;

    @include minmq($lg) {
      flex-direction: column;
      overflow-x: visible;
      position: sticky;
      top: 80px;
      left: 0;
    }

    // スクロールバーは非表示に
    /* IE, Edge 対応 */
    -ms-overflow-style: none;
    /* Firefox 対応 */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

  }

  &__navtitle {
    display: none;
    font-family: var(--font-satoshi);
    @include fontsize(24);
    font-weight: 900;
    color: var(--color-keynavy);
    text-transform: uppercase;

    @include minmq($lg) {
      display: block;
    }
  }

  &__navlist {
    display: flex;

    @include minmq($lg) {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
    }
  }

  &__navitem {
    flex: 0 0 auto;
  }

  &__navlink {
    display: inline-flex;
    justify-content: center;
    color: var(--color-keynavy);
    @include fontsize(14);
    font-family: var(--font-satoshi);
    font-weight: 500;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 16px;
    background: none;
    border-bottom: 1px solid var(--color-gray4);
    transition: color .3s var(--easing-cubic1);

    &[aria-current="page"] {
      color: var(--color-nazored);
      font-weight: 700;
      border-bottom-color: var(--color-nazored);
    }

    @include minmq($lg) {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      text-align: left;
      padding-top: 16px;
    }

    @include hover {
      color: var(--color-nazored);
    }
  }

}