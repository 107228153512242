@import 'function/var';

.main {

  &__outer {
    // padding: 56px 16px 80px;
    padding: 40px 16px 80px;

    @include minmq($xs) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @include minmq($lg) {
      // padding-top: 40px;
      padding-bottom: 120px;
    }

    &>.headgroup {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 56px;

      @include minmq($lg) {
        margin-bottom: 56px;
      }
    }

    &--block {
      display: grid;
      align-items: flex-start;
      grid-template-columns: 100%;
      gap: 56px clamp-px(24px, 40px, 120px, $lg);
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;

      @include minmq($lg) {
        display: grid;
        grid-template-columns: 168px 1fr;
        justify-content: space-between;
      }

      .news & {
        grid-template-columns: 100%;
        gap: 40px clamp-px(24px, 40px, 120px, $lg);

        @include minmq($lg) {
          grid-template-columns: 154px 1fr;
          grid-template-rows: auto 1fr;
        }
      }

      .news-onecolumn & {
        max-width: 1000px;
        gap: 40px clamp-px(24px, 40px, 120px, $lg);

        @include minmq($lg) {
          grid-template-columns: 1fr;
        }
      }

      .faq & {
        max-width: 1000px;
        gap: 40px clamp-px(24px, 40px, 120px, $lg);

        @include minmq($lg) {
          grid-template-columns: 1fr;
        }
      }

      .contact & {
        max-width: 1000px;
        // gap: clamp-px(56px, 68px, 80px, $lg) clamp-px(24px, 40px, 120px, $lg);
        gap: 40px clamp-px(24px, 40px, 120px, $lg);

        @include minmq($lg) {
          grid-template-columns: 1fr;
        }
      }

      .testrange & {
        max-width: 1000px;
        // gap: clamp-px(56px, 68px, 80px, $lg) clamp-px(24px, 40px, 120px, $lg);
        gap: 40px clamp-px(24px, 40px, 120px, $lg);

        @include minmq($lg) {
          grid-template-columns: 1fr;
        }
      }

      .pagenotfound & {
        max-width: 1000px;
        gap: 0;

        @include minmq($lg) {
          grid-template-columns: 1fr;
        }
      }
    }

  }

  &__container {
    width: 100%;

    @include minmq($lg) {
      max-width: 880px;
      margin-left: auto;
      order: 2;
    }

    .news & {
      @include minmq($lg) {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .faq & {
      @include minmq($lg) {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .contact & {
      @include minmq($lg) {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .testrange & {
      @include minmq($lg) {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .pagenotfound & {
      @include minmq($lg) {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__aside {
    display: none;
    max-width: 168px;
    width: 100%;

    @include minmq($lg) {
      display: block;
      height: 100%;
      order: 1;
    }

    .news & {
      display: block;
      max-width: 100%;
      margin-bottom: 0;

      @include minmq($lg) {
        max-width: 154px;
      }
    }

  }
}