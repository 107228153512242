@import 'function/var';

.sharelink {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .is-label {
    @include fontsize(16);
    font-weight: 500;
    text-align: center;
  }

  .is-snslink {
    display: flex;
    justify-content: center;
    gap: 6px;
  }

  @include minmq($md) {
    flex-direction: row;
    justify-content: flex-start;

    .is-label {
      text-align: right;
    }
  }
}