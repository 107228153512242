@import 'function/var';

.embed {
  &__video {
    position: relative;
    aspect-ratio: 700 / 384;

    &>* {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}