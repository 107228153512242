@import 'function/var';

.pagenotfound {

  &__text {
    @include fontsize(16);
    font-weight: 400;
    line-height: 2;
    text-align: center;
  }

  &__btn {
    margin-top: 56px;
    display: flex;
    justify-content: center;

    .btn__secondary {
      justify-content: center;
    }
  }
}