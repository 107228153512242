@import 'function/var'; //変数格納

// display
.d-none {
  display: none;
}

.d-block {
  display: block;
}

@include minmq($xs) {
  .d-xs-none {
    display: none;
  }

  .d-xs-block {
    display: block;
  }
}

@include minmq($sm) {
  .d-sm-none {
    display: none;
  }

  .d-sm-block {
    display: block;
  }
}

@include minmq($md) {
  .d-md-none {
    display: none;
  }

  .d-md-block {
    display: block;
  }
}

@include minmq($lg) {
  .d-lg-none {
    display: none;
  }

  .d-lg-block {
    display: block;
  }
}

@include minmq($xlg) {
  .d-xlg-none {
    display: none;
  }

  .d-xlg-block {
    display: block;
  }
}

.is-anchor {
  &::before {
    content: '';
    display: block;
    padding-top: var(--header-height);
    margin-top: calc(var(--header-height) * -1);
  }
}

.is-red {
  color: var(--color-nazored);
}

.mark {
  background: linear-gradient(transparent 66%, #FFCBCB 0%);
}

// Key frame Animation
[data-inview="up"] {
  opacity: 0;

  &.is-show {
    animation: inviewup .4s var(--easing-cubic1) forwards;
  }
}

[data-inview="down"] {
  opacity: 0;

  &.is-show {
    animation: inviewdown .4s var(--easing-cubic1) forwards;
  }
}

[data-inview="fade"] {
  opacity: 0;

  &.is-show {
    animation: inviewfadein .4s var(--easing-cubic1) forwards;
  }
}


@keyframes inviewfadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes inviewup {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@include minmq($lg) {
  @keyframes inviewup {
    from {
      opacity: 0;
      transform: translateY(40px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@keyframes inviewdown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@include minmq($lg) {
  @keyframes inviewdown {
    from {
      opacity: 0;
      transform: translateY(-40px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}