@import 'function/var';

.testrange {
  &__section {
    margin-top: 56px;

    @include minmq($lg) {
      margin-top: 120px;
    }
  }

  &__text {
    @include fontsize(16);
    font-weight: 400;
    line-height: 2;
    margin-bottom: 8px;
  }

  &__textlink {
    @include fontsize(16);
    font-weight: 400;
    line-height: 2;
    color: var(--color-txtlink);
    text-decoration: underline;
    text-underline-offset: 2px;
    transition: color .3s var(--easing-cubic1);

    &[target="_blank"] {
      &::after {
        display: inline-block;
        vertical-align: middle;
        content: "";
        width: 16px;
        height: 16px;
        margin-left: 4px;
        margin-right: 4px;
        mask-image: url('/assets/img/common/icon-blank.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: var(--color-nazored);
      }
    }

    @include hover {
      color: var(--color-nazored);
      text-decoration: none;
    }
  }

  &__box {
    padding: 32px 24px 40px;
    background-color: var(--color-bgbox);
    border-radius: var(--border-radius);

    &+& {
      margin-top: 24px;
    }

    @include minmq($lg) {
      padding: 80px;

      &+& {
        margin-top: 64px;
      }
    }

    &--title {
      @include kerning(30);
      color: var(--color-keynavy);
      font-size: clamp-px(20px, 22px, 24px, $lg);
      font-weight: 600;
      line-height: 1.6;
      margin-bottom: 24px;

      @include minmq($md) {
        margin-bottom: 32px;
      }

      @include minmq($lg) {
        margin-bottom: 40px;
      }
    }

    &--inner {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      gap: 40px;

      @include minmq($md) {
        // gap: 40px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
      }
    }

    &--example {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    &--answer {
      display: flex;
      justify-content: center;
      position: relative;

      .btn__secondary {
        align-self: center;

        &[aria-expanded="true"] {
          display: none;
        }

        .is-label {
          pointer-events: none;
        }
      }

      .testrange__box--img {
        &[aria-hidden="true"] {
          display: none;
        }

        &[aria-hidden="false"] {
          animation: inviewfadein .4s var(--easing-cubic1) forwards;
        }
      }
    }

    &--img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      img {
        width: 100%;
        max-width: 400px;
        border-radius: 16px;
        background-color: var(--color-white);
      }

    }
  }

  .btnbox {
    margin-top: 80px;

    @include minmq($lg) {
      margin-top: 120px;
    }
  }
}