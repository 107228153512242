@import 'function/var';

.faq {

  .ancarlist {
    margin-bottom: 0;
  }

  &__section {
    padding-top: 64px;

    &+& {
      padding-top: 80px;
    }

    @include minmq($lg) {
      padding-top: 80px;

      &+& {
        padding-top: 120px;
      }
    }
  }

  &__block {
    background-color: var(--color-bgbox);
    border: 1px solid var(--color-gray1);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-btn-default);
    overflow: hidden;

    &+& {
      margin-top: 32px;
    }

    &--question {
      position: relative;

      &::before {
        display: block;
        content: 'Q';
        color: var(--color-keynavy);
        font-family: var(--font-satoshi);
        font-size: clamp-px(24px, 28px, 32px, $lg);
        font-weight: 900;
        line-height: 1;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 1;
        pointer-events: none;

        @include minmq($lg) {
          top: 32px;
          left: 40px;
        }
      }
    }

    &--trigger {
      width: 100%;
      background: none;
      padding: 18px 64px 18px 48px;
      background-color: var(--color-white);
      text-align: left;
      position: relative;

      @include minmq($lg) {
        padding: 32px 96px 32px 88px;
      }

      .is-label {
        text-align: justify;
        font-size: clamp-px(16px, 18px, 18px, $lg);
        font-weight: 500;
        line-height: 1.8;
      }

      .is-toggle {
        width: clamp-px(16px, 24px, 32px, $lg);
        height: clamp-px(16px, 24px, 32px, $lg);
        background-color: var(--color-nazored);
        border-radius: var(--border-circle);
        position: absolute;
        top: 24px;
        right: 24px;
        line-height: 1;
        transition: background-color .3s var(--easing-cubic1);

        @include minmq($lg) {
          top: 32px;
          right: 40px;
        }

        &::before,
        &::after {
          display: block;
          content: "";
          width: clamp-px(10px, 14px, 18px, $lg);
          height: clamp-px(2px, 2px, 3px, $lg);
          background-color: var(--color-white);
          border-radius: var(--border-halfcircle);
          translate: 0 -50%;
          position: absolute;
          top: 50%;
          left: 50%;
          translate: -50% -50%;
          transition: background-color .3s var(--easing-cubic1), rotate .3s var(--easing-cubic1);
        }

        &::after {
          rotate: 90deg;
        }
      }

      &[aria-expanded="true"] {

        .is-toggle {
          background-color: var(--color-keynavy);

          &::after {
            rotate: 0deg;
          }
        }
      }
    }

    &--answer {
      display: none;
      width: 100%;
      padding: 18px 24px 18px 48px;
      background-color: var(--color-bgbox);
      text-align: left;
      position: relative;

      @include minmq($lg) {
        padding: 32px 40px 32px 88px;
      }

      &[aria-hidden="false"] {
        display: block;
        animation: inviewdown .4s var(--easing-cubic1) forwards;
      }

      &::before {
        display: block;
        content: 'A';
        color: var(--color-nazored);
        font-family: var(--font-satoshi);
        font-size: clamp-px(24px, 26px, 32px, $lg);
        font-weight: 900;
        line-height: 1;
        font-size: clamp-px(24px, 28px, 32px, $lg);
        font-weight: 900;
        line-height: 1;
        position: absolute;
        top: 20px;
        left: 21px;
        z-index: 1;
        pointer-events: none;

        @include minmq($lg) {
          top: 32px;
          left: 40px;
        }
      }
    }

    &--text {
      text-align: justify;
      font-size: clamp-px(16px, 18px, 18px, $lg);
      font-weight: 400;
      line-height: 1.8;

      &+& {
        margin-top: 1.5rem;
      }

      img {
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;
        margin-right: 4px;
      }

      a {
        display: inline-flex;
        gap: 16px;
        align-items: center;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
        transition: color .3s var(--easing-cubic1);

        // &::after {
        //   display: block;
        //   content: "";
        //   width: 6px;
        //   height: 12px;
        //   mask-image: url('/assets/img/common/icon-arrow2_right.svg');
        //   mask-size: contain;
        //   mask-repeat: no-repeat;
        //   mask-position: center;
        //   background: currentColor;
        //   transition: translate .3s var(--easing-cubic1);
        // }

        &[target="_blank"] {
          text-decoration: none;

          &::after {
            display: block;
            content: "";
            width: 16px;
            height: 16px;
            mask-image: url('/assets/img/common/icon-blank.svg');
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;
            background: currentColor;
            transition: translate .3s var(--easing-cubic1);
            background: var(--color-nazored);
            transition: background-color .3s var(--easing-cubic1);
          }
        }

        @include hover {
          color: var(--color-nazored);

          // &::after {
          //   translate: 5px 0;
          //   background-color: var(--color-nazored);
          // }

          &[target="_blank"] {
            text-decoration: none;

            &::after {
              translate: 0 0;
              background-color: var(--color-nazored);
            }
          }
        }
      }
    }

  }

}