@import 'function/var';

.news {
  .news__cat {
    .main__container {
      order: 3;
    }

    .main__aside {
      order: 2;
    }

    .headgroup {
      margin-bottom: 0;
      order: 1;
    }

    @include minmq($lg) {
      .main__container {
        order: inherit;
        grid-column: 2 / 3;
        grid-row: 2 / 2;
      }

      .main__aside {
        order: inherit;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
      }

      .headgroup {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        // margin-bottom: 40px;
        order: inherit;
      }
    }
  }

  &__archives {
    &.is-toparchive {
      margin-top: 8px;
      // @include minmq($lg) {
      //   margin-top: -40px;
      // }
    }
  }

  &__archivelist {
    border-top: 1px solid var(--color-gray4);

    // @include minmq($lg) {
    //   border-top: 1px solid var(--color-gray4);
    // }

    &--item {
      display: grid;
      gap: 8px 0;
      // grid-template-columns: auto 1fr;
      grid-template-columns: 114px 1fr;
      grid-template-rows: auto 1fr;
      grid-template-areas:
        'date cat'
        'posttitle posttitle';
      position: relative;
      padding: 24px 32px 24px 8px;
      border-bottom: 1px solid var(--color-gray4);
      background-color: transparent;
      transition: background-color .3s var(--easing-cubic1);

      @include minmq($lg) {
        gap: 12px;
        grid-template-columns: 100px auto 1fr;
        grid-template-rows: auto;
        grid-template-areas: 'date cat posttitle';
        position: relative;
        padding: 32px 56px 32px 24px;
      }

      &::after {
        display: block;
        content: "";
        width: 14px;
        height: 14px;
        mask-image: url('/assets/img/common/icon-arrow1_right.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: var(--color-nazored);
        position: absolute;
        top: 50%;
        right: 9px;
        translate: 0 -50%;
        transition: background-color .3s var(--easing-cubic1);

        @include minmq($lg) {
          right: 25px;
        }
      }

      &::before {
        display: block;
        content: "";
        width: 16px;
        height: 16px;
        background-color: var(--color-white);
        border-radius: var(--border-circle);
        position: absolute;
        top: 50%;
        right: 8px;
        translate: 0 -50%;
        transition: background-color .3s var(--easing-cubic1);

        @include minmq($lg) {
          right: 24px;
        }
      }

      @include hover {
        background-color: rgba($color: #343131, $alpha: .1);

        &::after {
          background-color: var(--color-white);
        }

        &::before {
          background-color: var(--color-nazored);
        }
      }
    }

    &--date {
      display: inline-flex;
      align-items: center;
      grid-area: date;
      color: var(--color-keynavy);
      font-family: var(--font-satoshi);
      font-weight: 900;
      line-height: 1.2;
      padding-right: 12px;
      border-right: 1px solid var(--color-keynavy);

      time {
        display: inline-flex;
        align-items: center;
        gap: 0 4px;

        @include minmq($lg) {
          flex-wrap: wrap
        }
      }

      .is-year {
        @include fontsize(10);
        font-weight: 900;

        @include minmq($lg) {
          @include fontsize(11);
          flex: 0 1 100%;
        }
      }

      .is-day {
        @include fontsize(16);
        font-weight: 900;

        @include minmq($lg) {
          @include fontsize(18);
        }
      }

      .is-weekly {
        @include fontsize(10);
        font-weight: 900;
        text-transform: uppercase;
        align-self: flex-end;
        translate: 0 -1px;
      }
    }

    &--category {
      display: inline-flex;
      align-items: center;
      grid-area: cat;
      padding-left: 12px;

      @include minmq($lg) {
        padding-left: 0;
      }
    }

    &--posttitle {
      @include minmq($lg) {
        display: flex;
        align-items: center;
      }

      grid-area: posttitle;
    }

    &--link {
      color: var(--color-keynavy);
      font-size: clamp-px(14px, 16px, 16px, $lg);
      font-weight: 500;
      line-height: 1.8;
      @include line-clamp(2);

      @include minmq($lg) {
        @include line-clamp(1);
      }

      &::before {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__category {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 20px;
    font-size: clamp-px(11px, 12px, 12px, $lg);
    font-weight: 500;
    line-height: 1.4;
    color: var(--color-white);
    background-color: var(--color-keynavy);
    border-radius: var(--border-halfcircle);
  }


  // 詳細ページ
  .news__article {
    .main__container {
      order: 2;
    }

    .main__aside {
      order: 1;
    }

  }

  &__posttitle {
    padding-bottom: 32px;
    border-bottom: 1px solid var(--color-gray4);

    &--status {
      display: flex;
      gap: 24px;
      margin-bottom: 24px;
    }

    .news__archivelist--date {
      flex: 0 0 100px;
    }

    &--label {
      font-size: clamp-px(24px, 28px, 32px, $lg);
      font-weight: 500;
      line-height: 1.6;
      color: var(--color-keynavy);
    }
  }

  &__postfooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px 16px;
    padding-top: 56px;
    border-top: 1px solid var(--color-gray4);

    @include minmq($lg) {
      gap: 0 32px;
      justify-content: flex-end;
      padding-top: 80px;
      padding-left: 24px;
      padding-right: 24px;
    }

    &--inner {
      flex: 0 1 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0 16px;

      @include minmq($md) {
        flex: 0 1 auto;
        gap: 0 32px;
        justify-content: flex-end;
      }
    }

    .sharelink {
      flex: 0 1 100%;
      @include minmq($md) {
        flex: 0 1 auto;
        margin-right: auto;
      }
    }
  }

  &__singleback {
    flex: 0 1 auto;

    &--link {
      color: var(--color-keynavy);
      @include fontsize(20);
      font-weight: 500;
      transition: color .3s var(--easing-cubic1);

      @include hover {
        color: var(--color-gray3);
      }
    }
  }

  &__singlepager {
    display: flex;
    align-items: center;
    gap: 24px;

    &--item {
      flex: 0 0 auto;
    }

    &--link {
      display: grid;
      place-items: center;
      text-indent: 9999px;
      font-size: 0px;
      line-height: 0;
      width: 40px;
      height: 40px;
      overflow: hidden;
      position: relative;
      border: 1px solid var(--color-keynavy);
      border-radius: var(--border-circle);
      transition: background-color .3s var(--easing-cubic1), border-color .3s var(--easing-cubic1);

      &::after {
        display: block;
        content: "";
        width: 16px;
        height: 16px;
        mask-image: url('/assets/img/common/icon-arrow1_slide.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: var(--color-keynavy);
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        transition: background-color .3s var(--easing-cubic1);
      }

      @include hover {
        background-color: var(--color-nazored);
        border-color: var(--color-nazored);

        &::after {
          background: var(--color-white);
        }
      }

      &.is-prev {
        &::after {
          transform: scale(-1, 1);
        }
      }
    }
  }

  &__pagerfooter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 16px;
    padding-top: 56px;

    @include minmq($lg) {
      gap: 0 32px;
      padding-top: 80px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &__pager {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px 6px;

    @include minmq($xs) {
      gap: 16px 8px;
    }

    @include minmq($sm) {
      gap: 16px;
    }

    &--item {
      flex: 0 0 auto;
    }

    .page-numbers {
      display: grid;
      place-items: center;
      width: 36px;
      height: 36px;
      @include fontsize(14);
      font-weight: 900;
      font-family: var(--font-satoshi);
      position: relative;
      border: 1px solid var(--color-keynavy);
      border-radius: var(--border-circle);
      transition: background-color .3s var(--easing-cubic1), border-color .3s var(--easing-cubic1), color .3s var(--easing-cubic1);

      @include minmq($xs) {
        width: 40px;
        height: 40px;
        @include fontsize(15);
      }

      @include minmq($sm) {
        width: 48px;
        height: 48px;
      }

      @include hover {
        color: var(--color-gray3);
        border-color: var(--color-gray3);
      }

      &[aria-current="page"] {
        color: var(--color-white);
        background-color: var(--color-keynavy);

        @include hover {
          color: var(--color-white);
          border-color: var(--color-keynavy);
        }
      }

      &.dots {
        width: auto;
        height: auto;
        border: none;
      }
    }

    &--arr {
      a {
        display: grid;
        place-items: center;
        text-indent: 9999px;
        font-size: 0px;
        line-height: 0;
        width: 22px;
        height: 22px;
        overflow: hidden;
        position: relative;
        border: 1px solid var(--color-keynavy);
        border-radius: var(--border-circle);
        transition: background-color .3s var(--easing-cubic1), border-color .3s var(--easing-cubic1);

        @include minmq($xs) {
          width: 26px;
          height: 26px;
        }

        &::after {
          display: block;
          content: "";
          width: 10px;
          height: 10px;
          mask-image: url('/assets/img/common/icon-arrow1_slide.svg');
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
          background: var(--color-keynavy);
          position: absolute;
          top: 50%;
          left: 50%;
          translate: -50% -50%;
          transition: background-color .3s var(--easing-cubic1);

          @include minmq($xs) {
            width: 12px;
            height: 12px;
          }
        }

        @include hover {
          background-color: var(--color-nazored);
          border-color: var(--color-nazored);

          &::after {
            background: var(--color-white);
          }
        }
      }

      span {
        display: grid;
        place-items: center;
        text-indent: 9999px;
        font-size: 0px;
        line-height: 0;
        width: 26px;
        height: 26px;
        overflow: hidden;
        position: relative;
        border: 1px solid var(--color-gray4);
        border-radius: var(--border-circle);

        &::after {
          display: block;
          content: "";
          width: 12px;
          height: 12px;
          mask-image: url('/assets/img/common/icon-arrow1_slide.svg');
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
          background: var(--color-gray4);
          position: absolute;
          top: 50%;
          left: 50%;
          translate: -50% -50%;
        }
      }

      &.is-prev {
        margin-right: 4px;

        @include minmq($xs) {
          margin-right: 8px;
        }

        span,
        a {
          &::after {
            transform: scale(-1, 1);
          }
        }
      }

      &.is-next {
        margin-left: 4px;

        @include minmq($xs) {
          margin-left: 8px;
        }
      }
    }
  }


  &__postbody {
    padding-top: 32px;
    padding-bottom: 56px;

    >*:first-child {
      margin-top: 0;
    }

    @include minmq($lg) {
      padding: 40px 24px 80px;
    }

    // カラム
    .wp-block-columns {
      margin-top: 56px;
      margin-bottom: 56px;
    }

    .wp-block-column {
      >*:first-child {
        margin-top: 0;
      }
    }


    // 見出し
    .is-style-stylelv2,
    h2 {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      gap: clamp-px(12px, 14px, 16px, $lg);
      color: var(--color-keynavy);
      font-size: clamp-px(28px, 30px, 32px, $lg);
      font-weight: 500;
      line-height: 1.4;
      margin-top: 56px;
      margin-bottom: 40px;

      &::before {
        flex: 0 0 auto;
        display: block;
        content: '';
        width: clamp-px(14px, 15px, 16px, $lg);
        height: clamp-px(14px, 15px, 16px, $lg);
        background-color: var(--color-nazored);
        border-radius: var(--border-circle);
        translate: 0 13px;
      }

      @include minmq($lg) {
        margin-top: 120px;
        margin-bottom: 56px;

        &::before {
          translate: 0 16px;
        }
      }

      &.is-style-stylelv3,
      &.is-style-stylelv4,
      &.is-style-stylelv5 {
        &::before {
          display: none;
        }
      }
    }

    .is-style-stylelv3,
    h3 {
      font-size: clamp-px(24px, 26px, 28px, $lg);
      font-weight: 500;
      line-height: 1.4;
      margin-top: 32px;
      margin-bottom: 24px;

      @include minmq($lg) {
        margin-top: 40px;
      }
    }

    .is-style-stylelv4,
    h4 {
      font-size: clamp-px(20px, 22px, 24px, $lg);
      font-weight: 500;
      line-height: 1.6;
      margin-top: 24px;
      margin-bottom: 16px;

      @include minmq($lg) {
        margin-top: 32px;
      }
    }

    .is-style-stylelv5,
    h5 {
      @include fontsize(18);
      font-weight: 500;
      line-height: 1.6;
      margin-top: 16px;
      margin-bottom: 16px;

      @include minmq($lg) {
        margin-top: 24px;
      }
    }

    // テキスト
    p {
      @include fontsize(16);
      font-weight: 400;
      line-height: 2;

      &+p {
        margin-top: 1rem;
      }
    }

    // キャプション
    .wp-element-caption {
      color: var(--color-gray3);
      @include fontsize(14);
      font-weight: 400;
      line-height: 1.8;
      margin-top: 10px;
    }

    // 背景色

    .has-background {
      &.has-basetxt-background-color {
        background-color: var(--color-gray1);
      }

      &.has-red-background-color {
        background-color: var(--color-nazored);
      }

      &.has-navy-background-color {
        background-color: var(--color-keynavy);
      }

      &.has-gray1-background-color {
        background-color: var(--color-gray2);
      }

      &.has-gray2-background-color {
        background-color: var(--color-gray3);
      }

      &.has-gray3-background-color {
        background-color: var(--color-gray4);
      }

      &.has-gray4-background-color {
        background-color: var(--color-gray5);
      }

      &.has-black-background-color {
        background-color: var(--color-black);
      }

      &.has-white-background-color {
        background-color: var(--color-white);
      }
    }

    // リンク
    a {
      color: var(--color-txtlink);
      text-decoration: underline;
      text-underline-offset: 2px;
      transition: color .3s var(--easing-cubic1);

      &[target="_blank"] {
        &::after {
          display: inline-block;
          vertical-align: middle;
          content: "";
          width: 16px;
          height: 16px;
          margin-left: 4px;
          margin-right: 4px;
          mask-image: url('/assets/img/common/icon-blank.svg');
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
          background: var(--color-nazored);
        }
      }

      &[href$=".pdf"] {
        &::after {
          display: inline-block;
          vertical-align: middle;
          content: "";
          width: 16px;
          height: 16px;
          margin-left: 4px;
          margin-right: 4px;
          mask-image: url('/assets/img/common/icon-file.svg');
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
          background: var(--color-nazored);
        }
      }

      @include hover {
        color: var(--color-nazored);
        text-decoration: none;
      }
    }

    // リスト
    ul {
      margin-left: 1.5em;

      li {
        list-style: disc;
        font-weight: 400;
        line-height: 2;

        &+& {
          margin-top: 4px;
        }
      }
    }

    ol {
      margin-left: 1.5em;

      li {
        list-style: decimal;
        font-weight: 400;
        line-height: 2;

        &+& {
          margin-top: 4px;
        }
      }
    }

    .is-style-indentlist {
      margin-left: 0;

      li {
        text-indent: -1em;
        padding-left: 1em;
        @include fontsize(14);
        font-weight: 400;
        line-height: 1.8;
        list-style: none;

        &+& {
          margin-top: 4px;
        }
      }
    }

    // ボタン
    .wp-block-button {
      &.is-style-btn-primary {

        .wp-block-button__link {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          gap: clamp-px(8px, 12px, 12px, $lg);
          max-width: 440px;
          width: 100%;
          min-height: clamp-px(56px, 58px, 60px, $lg);
          padding: 16px 40px;
          color: var(--color-white);
          @include fontsize(16);
          font-weight: 700;
          line-height: 1.4;
          text-decoration: none;
          background-color: var(--color-btn-primary);
          border: 2px solid var(--color-btn-primary);
          border-radius: var(--border-halfcircle);
          box-shadow: var(--shadow-btn-default);
          transition: box-shadow .15s var(--easing-cubic1), translate .15s var(--easing-cubic1);
          position: relative;

          &[target="_blank"] {
            &::after {
              background: var(--color-white);
            }
          }

          @include hover {
            translate: 0 2px;
            box-shadow: 0 4px 0 rgba(23 27 60 / .13);
          }
        }
      }

      &.is-style-btn-secondary {
        .wp-block-button__link {
          display: flex;
          align-items: center;
          justify-content: center;
          word-break: break-all;
          max-width: 440px;
          width: 100%;
          color: var(--color-keynavy);
          @include fontsize(16);
          font-weight: 500;
          line-height: 1.4;
          min-height: clamp-px(56px, 58px, 60px, $lg);
          gap: clamp-px(8px, 12px, 12px, $lg);
          padding: 16px 24px;
          border: 2px solid var(--color-btn-line);
          border-radius: var(--border-halfcircle);
          background-color: var(--color-btn-secondary);
          box-shadow: var(--shadow-btn-default);
          text-decoration: none;
          transition: box-shadow .15s var(--easing-cubic1), translate .15s var(--easing-cubic1), color .3s var(--easing-cubic1);
          position: relative;

          &[target="_blank"] {
            &::after {
              display: block;
              content: "";
              width: 16px;
              height: 16px;
              mask-image: url('/assets/img/common/icon-blank.svg');
              mask-size: contain;
              mask-repeat: no-repeat;
              mask-position: center;
              background: var(--color-keynavy);
              position: absolute;
              top: 52%;
              right: 20px;
              translate: 0 -50%;
              transition: background-color .3s var(--easing-cubic1);
            }
          }

          @include hover {
            color: var(--color-nazored);
            translate: 0 2px;
            box-shadow: 0 4px 0 rgba(23 27 60 / .13);

            &::after {
              translate: 5px -50%;
              background: var(--color-nazored);
            }

            &[target="_blank"] {
              &::after {
                translate: 0 -50%;
              }
            }
          }
        }
      }

    }


    // 画像
    .wp-block-image {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .wp-block-image .is-style-rounded img,
    .wp-block-image.is-style-circle-mask img,
    .wp-block-image.is-style-rounded img {
      border-radius: var(--border-radius);
    }

    // 埋め込み - YouTube
    .wp-block-embed {
      margin-top: 32px;
      margin-bottom: 32px;

      &__wrapper {
        position: relative;
        aspect-ratio: 700 / 384;

        &>* {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }

    // テーブル
    .wp-block-table {
      width: 100%;
      margin-top: 32px;
      margin-bottom: 32px;
      overflow-x: auto;

      table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
      }

      th,
      td {
        font-weight: 500;
        line-height: 2;
        padding: 10px;
        border: 1px solid var(--color-gray4);

        &>*:first-child {
          margin-top: 0;
        }
      }

      thead {
        border: none;

        th,
        td {
          font-weight: 400;
          background-color: var(--color-gray5);
          border-top: 1px solid var(--color-gray4);
          border-bottom: 1px solid var(--color-gray4);
        }
      }

      tbody {
        border: none;

        th,
        td {
          font-weight: 500;
          line-height: 2;
          padding: 10px;
          border-bottom: 1px solid var(--color-gray4);
        }
      }

      tfoot {
        border: none;

        th,
        td {
          font-weight: 400;
          line-height: 2;
          padding: 10px;
        }
      }

      &.is-style-stripes {
        border: none;

        tbody {
          tr:nth-child(odd) {
            background: none;
          }

          tr:nth-child(even) {
            background: var(--color-bgbox);
          }
        }
      }
    }


  }

}