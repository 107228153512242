@import 'function/var';

// 初めての謎検 スタイル


// MARK:謎検とは？
.about {

  .is-notice {
    @include fontsize(12);

    .indentlist__item {
      margin-top: 4px;
    }

    @include minmq($md) {
      @include fontsize(14);
    }
  }

  &__section {
    margin-top: 80px;

    @include minmq($lg) {
      margin-top: 120px;
    }

    &.is-message {
      .title__style3 {
        margin-bottom: 32px;

        @include minmq($lg) {
          margin-bottom: 40px;
        }
      }
    }
  }

  &__movie {
    margin-bottom: clamp-px(24px, 28px, 32px, $lg);
  }

  &__text {
    @include fontsize(16);
    font-weight: 400;
    line-height: 2;

    &+& {
      margin-top: 16px;

      .is-message & {
        margin-top: 24px;
      }
    }
  }

  &__name {
    color: var(--color-gray3);
    @include fontsize(14);
    font-weight: 400;
    line-height: 1.6;
    margin-top: 32px;
  }

  &__ability {
    display: flex;
    flex-direction: column;
    gap: 24px 32px;
    grid-template-columns: 1fr;
    margin-top: clamp-px(32px, 40px, 56px, $lg);
    margin-bottom: clamp-px(32px, 40px, 56px, $lg);

    @include minmq($md) {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
    }

    &--item {
      flex: 0 1 100%;
      display: grid;
      gap: 8px 16px;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto 1fr;
      grid-template-areas:
        'iconmark genre'
        'iconmark txt';
      padding: 24px 16px;
      background-color: var(--color-cyan);
      border-radius: var(--border-radius);

      @include minmq($md) {
        flex: 0 1 calc((100% / 3) - 24px);
        gap: 12px;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
        grid-template-areas:
          'iconmark'
          'genre'
          'txt';
        padding: 32px 40px;
      }
    }

    &--mark {
      grid-area: iconmark;
      display: flex;
      justify-content: center;
    }

    &--title {
      grid-area: genre;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      max-width: 136px;
      width: 100%;
      padding: 6px 10px;
      // font-size: clamp-px(14px, 16px, 16px, $lg);
      @include fontsize(16);
      font-weight: 900;
      line-height: 1.4;
      color: var(--color-keynavy);
      background-color: var(--color-white);
      border: 2px solid var(--color-keynavy);
      border-radius: 6px;

      @include minmq($md) {
        // max-width: 136px;
        // padding-top: 8px;
        // padding-bottom: 8px;
        margin-left: auto;
        margin-right: auto;
        // border-radius: 6px;
      }
    }

    &--text {
      grid-area: txt;
      font-size: clamp-px(15px, 16px, 16px, $lg);
      font-weight: 400;
      line-height: 1.8;

      @include minmq($md) {
        margin-top: 12px;
      }
    }
  }

  &__merit {
    @include minmq($md) {
      display: flex;
      justify-content: center;
      gap: 24px;

      &--item {
        flex: 0 1 auto;
      }
    }

    &--block {
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
      padding: 16px 0;

      @include minmq($md) {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }

    &--mark {
      display: flex;
      justify-content: center;
    }

    &--title {
      margin-top: 16px;
      @include fontsize(18);
      font-weight: 700;
      line-height: 1.6;
      text-align: center;
    }

    &--text {
      @include fontsize(16);
      font-weight: 500;
      line-height: 1.8;
      margin-top: 8px;
    }

    &--slider {
      margin-top: 40px;
      padding: 24px;
      margin-left: -16px;
      margin-right: -16px;
      background-color: var(--color-bgbox);
      position: relative;

      @include minmq($xs) {
        margin-left: -24px;
        margin-right: -24px;
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: 38px;
        height: 44px;
        background: url('/assets/img/common/icon-arrow2_slide.svg') no-repeat;
        background-size: contain;
        border-radius: 0;
        border: none;
        position: absolute;
        top: 38%;

        &::after {
          display: none;
        }
      }

      .swiper-button-prev {
        left: 0;
        transform: scale(-1, 1);
        translate: 0 -50%;
      }

      .swiper-button-next {
        right: 0;
        translate: 0 -50%;
      }
    }
  }

  &__report {
    @include minmq($md) {
      display: grid;
      gap: 40px;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 40px;
      padding: clamp-px(56px, 60px, 64px, $lg);
      border: 1px solid var(--color-gray1);
      border-radius: var(--border-radius);
    }

    &--block {
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
      // padding-left: 24px;
      // padding-right: 24px;

      @include minmq($lg) {
        max-width: 100%;
        // padding-left: 0;
        // padding-right: 0;
      }

      img {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &--slider {
      margin-top: 40px;
      padding: 24px;
      margin-left: -16px;
      margin-right: -16px;
      background-color: var(--color-bgbox);
      position: relative;

      img {
        border: 1px solid var(--color-gray1);
      }

      @include minmq($xs) {
        margin-left: -24px;
        margin-right: -24px;
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: 38px;
        height: 44px;
        background: url('/assets/img/common/icon-arrow2_slide.svg') no-repeat;
        background-size: contain;
        border-radius: 0;
        border: none;
        position: absolute;
        top: 38%;

        &::after {
          display: none;
        }
      }

      .swiper-button-prev {
        left: 0;
        transform: scale(-1, 1);
        translate: 0 -50%;
      }

      .swiper-button-next {
        right: 0;
        translate: 0 -50%;
      }
    }

    &--caption {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

  }
}

// MARK:まるわかりガイド
.commentary {

  &__section {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      'conts'
      'mv'
      'related';
    gap: 32px;
    padding-bottom: 32px;

    &:not(:first-of-type) {
      padding-top: 32px;
      border-top: 1px solid var(--color-gray4);
    }

    @include minmq($md) {
      gap: 40px 32px;
      padding-bottom: 56px;

      &:not(:first-of-type) {
        padding-top: 56px;
      }

    }

    @include minmq($lg) {
      grid-template-columns: 1fr 590px;
      grid-template-rows: auto 1fr;
      grid-template-areas:
        'conts mv'
        'related mv';
    }

    .title__style2,
    .title__style3 {
      margin-bottom: 24px;
    }
  }

  &__text {
    font-size: clamp-px(16px, 18px, 18px, $lg);
    font-weight: 400;
    line-height: 2;
    margin-bottom: 32px;

    @include minmq($md) {
      margin-bottom: 56px;
    }
  }

  &__contents {
    grid-area: conts;
  }

  &__video {
    grid-area: mv;
  }

  &__related {
    grid-area: related;
  }

}

// MARK:過去データ
.result {

  &__section {

    &+& {
      margin-top: 56px;

      @include minmq($lg) {
        margin-top: 120px;
      }
    }
  }

  &__text {
    @include fontsize(18);
    font-weight: 400;
    line-height: 2;
    margin-bottom: 56px;

    @include minmq($lg) {
      margin-bottom: 56px;
    }
  }

  &__tablewrap {}

  &__table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  &__thead {
    &--th {
      font-size: clamp-px(14px, 16px, 16px, $lg);
      font-weight: 400;
      line-height: 2;
      text-align: center;
      padding: 10px;
      background-color: var(--color-gray5);
      border-top: 1px solid var(--color-gray4);
      border-bottom: 1px solid var(--color-gray4);
    }
  }

  &__tbody {
    &--td {
      font-size: clamp-px(16px, 20px, 20px, $lg);
      font-weight: 500;
      line-height: 2;
      text-align: center;
      padding: 10px;
      border-bottom: 1px solid var(--color-gray4);

      b {
        font-family: var(--font-satoshi);
        font-size: 160%;
        font-weight: 500;
      }
    }

    &--medal {
      display: flex;
      justify-content: center;
      gap: 8px;
    }

    &--num {
      display: flex;
      justify-content: center;
      align-items: baseline;
    }
  }

  &__graph {
    margin-top: 40px;

    &--title {
      @include fontsize(20);
      font-weight: 500;
      line-height: 1.6;
      margin-bottom: 24px;
    }

    &--desc {
      display: flex;
      justify-content: center;
    }

    &--img {
      max-width: 880px;
    }

    &--caption {
      margin-top: 8px;
      font-size: clamp-px(14px, 16px, 16px, $lg);
      text-align: right;
      font-weight: 500;
      line-height: 1.6;
    }
  }

}

// MARK:受検者の声
.comment {
  &__text {
    font-size: clamp-px(16px, 18px, 18px, $lg);
    font-weight: 400;
    line-height: 2;
    margin-bottom: 32px;

    @include minmq($md) {
      margin-bottom: 56px;
    }
  }

  &__box {
    padding: 24px;
    background-color: var(--color-bgbox);
    border-radius: var(--border-radius);
    position: relative;

    &+& {
      margin-top: 32px;

      @include minmq($md) {
        margin-top: 56px;
      }
    }

    &.is-famous {
      display: grid;
      grid-template-columns: 1fr;
      gap: 24px;
      // grid-template-areas:
      //   'title'
      //   'txt'
      //   'img';
      grid-template-areas:
        'txt'
        'img';

      .title__style1 {
        margin-bottom: 0;
        // margin-bottom: 24px;
        grid-area: title;
      }

      @include minmq($md) {
        grid-template-columns: 1fr 160px;
        align-items: center;
        grid-template-areas:
          'txt img';
        // grid-template-areas:
        //   'title title'
        //   'txt img';

        .title__style1 {
          margin-bottom: 8px;
        }

      }
    }

    &.is-person {
      display: grid;
      gap: 24px 16px;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      grid-template-rows: 1fr auto;
      grid-template-areas:
        'txt txt'
        'img name';

      @include minmq($md) {
        gap: 16px 24px;
        grid-template-columns: 1fr 90px;
        grid-template-rows: 1fr auto;
        grid-template-areas:
          'txt img'
          'name img';
      }
    }

    &::before {
      display: block;
      content: '';
      width: 38px;
      height: 39px;
      background: url('/assets/img/common/icon-voice.svg') center no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
      translate: calc(-50% + 6px) -50%;
    }

    @include minmq($xs) {
      &::before {
        translate: -50% -50%;
      }
    }

    @include minmq($md) {
      padding: 40px;

      &::before {
        width: 59px;
        height: 60px;
        translate: calc(-50% + 6px) -50%;
      }
    }

    &--block {
      grid-area: txt;

      .is-person & {
        text-align: left;
      }
    }

    &--text {
      @include fontsize(16);
      font-weight: 400;
      line-height: 2;

      &+& {
        margin-top: 16px;
      }
    }

    &--img {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      grid-area: img;

      @include minmq($md) {
        flex-direction: column;
      }

      .is-person & {
        justify-content: flex-end;

        @include minmq($md) {
          justify-content: center;
        }
      }
    }

    &--name {
      font-size: clamp-px(14px, 16px, 16px, $lg);
      font-weight: 500;
      line-height: 1.5;
      text-align: center;

      .is-person & {
        align-self: center;
        text-align: left;
        grid-area: name;
      }
    }
  }

}

// MARK:インタビュー
.interview {
  &__section {
    &+& {
      margin-top: 56px;
    }
  }

  &__lead {
    font-size: clamp-px(15px, 16px, 16px, $lg);
    font-weight: 400;
    line-height: 2;
    margin-bottom: 32px;

    @include minmq($md) {
      margin-bottom: 56px;
    }
  }

  &__text {
    font-size: clamp-px(15px, 16px, 16px, $lg);
    font-weight: 400;
    line-height: 2;

    &+& {
      margin-top: 16px;
    }
  }

  &__question {
    color: var(--color-gray3);
    font-size: clamp-px(15px, 16px, 16px, $lg);
    font-weight: 500;
    line-height: 2;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  &__img {
    margin-top: 32px;
    margin-bottom: 32px;

    &--caption {
      color: var(--color-gray2);
      @include fontsize(14);
      font-weight: 400;
      line-height: 1.8;
      margin-top: 10px;
    }

    img {
      margin-left: auto;
      margin-right: auto;
      border-radius: var(--border-radius);
    }

    @include minmq($md) {
      margin-top: 56px;
      margin-bottom: 56px;

      img {
        max-width: 100%;
      }
    }
  }

  &__block {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;

    &+& {
      margin-top: 40px;
    }

    .interview__img {
      margin-top: 0;
      margin-bottom: 0;
    }

    @include minmq($md) {
      grid-template-columns: 1fr auto;

      .interview__question:first-of-type,
      .interview__text:first-of-type {
        margin-top: 0;
      }
    }
  }

  &__listener {
    margin-top: 56px;
    padding-top: 32px;
    padding-bottom: 32px;
    border-top: 1px solid var(--color-gray1);
    border-bottom: 1px solid var(--color-gray1);

    @include minmq($md) {
      padding-top: 56px;
      padding-bottom: 56px;
      margin-top: 80px;
    }

    &--text {
      @include fontsize(14);
      font-weight: 400;
      line-height: 1.8;

      &+& {
        margin-top: 16px;
      }

      a {
        // text-decoration: underline;

        &[target="_blank"] {
          &::after {
            display: inline-flex;
            content: "";
            width: 16px;
            height: 16px;
            mask-image: url('/assets/img/common/icon-blank.svg');
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;
            background: var(--color-keynavy);
            margin-left: 8px;
            translate: 0 3px;
            transition: background-color .3s var(--easing-cubic1);
          }
        }

        @include hover {
          // text-decoration: none;

          &[target="_blank"] {
            &::after {
              background: var(--color-nazored);
            }
          }
        }
      }
    }
  }

  .pagenav {
    margin-top: 64px;
  }
}