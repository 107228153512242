@import 'function/var';

.swiper-operation {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 24px;
  grid-template-columns: 1fr;
}

// 矢印・再生ボタン
.swiper-controller {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.swiper-button-prev,
.swiper-button-next {
  display: grid;
  place-items: center;
  position: static;
  width: 40px;
  height: 40px;
  margin: 0;
  border: 2px solid var(--color-white);
  border-radius: var(--border-circle);

  &::after {
    display: block;
    content: "";
    width: 16px;
    height: 16px;
    mask-image: url('/assets/img/common/icon-arrow1_slide.svg');
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background: var(--color-white);
  }
}

.swiper-button-prev {
  &::after {
    transform: scale(-1, 1);
  }
}

.swiper-playpause {
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  margin: 0;
  background: none;
  border: 2px solid var(--color-white);
  border-radius: var(--border-circle);

  &::after {
    display: block;
    content: "";
    width: 16px;
    height: 16px;
    mask-image: url('/assets/img/common/icon-stop_slide.svg');
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background: var(--color-white);
  }

  &.is-pause {
    &::after {
      mask-image: url('/assets/img/common/icon-play_slide.svg');
      mask-size: contain;
    }
  }
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  position: static;

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    opacity: 1;
    background-color: var(--color-white);
  }

  .swiper-pagination-bullet-active {
    background-color: var(--color-nazored);
  }
}


// ページナビスライド
.pagenav {
  margin-left: -16px;
  margin-right: -16px;
  position: relative;
  overflow: hidden;

  @include minmq($xs) {
    margin-left: -24px;
    margin-right: -24px;
  }

  @include minmq($md) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  &__title {
    @include fontsize(24);
    font-weight: 500;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 32px;
  }

  .swiper-slide {
    max-width: 240px;
    height: auto;

    @include minmq(380px) {
      max-width: 340px;
    }
  }

  .swiper-operation {
    margin-top: 32px;
  }

  // 矢印・再生ボタン
  .swiper-button-prev,
  .swiper-button-next {
    width: 32px;
    height: 32px;
    border: 2px solid var(--color-keynavy);

    &::after {
      background: var(--color-keynavy);
    }
  }

  .swiper-button-prev {
    &::after {
      transform: scale(-1, 1);
    }
  }

  .swiper-playpause {
    width: 32px;
    height: 32px;
    border: 2px solid var(--color-keynavy);

    &::after {
      background: var(--color-keynavy);
    }

  }

  .swiper-pagination {

    .swiper-pagination-bullet {
      background-color: var(--color-gray3);
    }

    .swiper-pagination-bullet-active {
      background-color: var(--color-nazored);
    }
  }

  &__slider {

    // カード
    &--card {
      display: block;
      max-width: 256px;
      height: 100%;
      padding: 16px;
      color: var(--color-keynavy);
      border: 2px solid var(--color-keynavy);
      background-color: var(--color-white);
      border-radius: var(--border-radius);
      box-shadow: var(--shadow-btn-default);
      overflow: hidden;
      position: relative;

      @include minmq($xs) {
        max-width: 340px;
        padding: 24px 16px;
      }

      @include hover {
        color: var(--color-nazored);
      }
    }

    &--thumbnail {
      display: grid;
      gap: 16px;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      align-items: flex-start;
      margin-bottom: 0;
    }

    &--img {
      display: flex;
      justify-content: center;

      img {
        max-width: 100%;
        height: auto;
      }

    }

    &--posttitle {
      font-weight: 500;
      line-height: 1.6;
      @include fontsize(16);
      @include line-clamp(2);
      transition: color .3s var(--easing-cubic1);
    }
  }
}