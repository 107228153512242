@import 'function/var';

.guide {

  .indentlist {
    margin-top: 8px;
  }

  &__section {
    margin-top: 56px;

    @include minmq($lg) {
      margin-top: 120px;

      &:first-of-type {
        margin-top: 56px;
      }

      &+& {
        margin-top: 120px;
      }
    }
  }

  &__kv {
    // max-width: 1200px;
    // margin-left: auto;
    // margin-right: auto;
    // margin-bottom: 40px;

    img {
      width: 100%;
    }

    @include minmq($lg) {
      margin-bottom: 64px;
    }
  }

  &__text {
    @include fontsize(16);
    font-weight: 400;
    line-height: 2;

    &+& {
      margin-top: 16px;
    }
  }

  &__box {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    padding: 32px;
    background-color: var(--color-bgbox);
    border-radius: var(--border-radius);

    @include minmq($md) {
      padding: 64px clamp-px(40px, 60px, 80px, $lg);
    }

    &--title {
      @include kerning(30);
      font-size: clamp-px(20px, 22px, 24px, $lg);
      font-weight: 500;
      line-height: 1.6;
      margin-bottom: 4px;
    }

    &--date {
      color: var(--color-nazored);
      font-size: clamp-px(20px, 24px, 28px, $lg);
      font-weight: 500;
      line-height: 1.8;
    }

    .indentlist__item {
      color: var(--color-gray2);
    }
  }

  &__check {
    margin-top: 56px;

    @include minmq($lg) {
      margin-top: 80px;
    }
  }

  &__notice {
    // margin-top: 24px;
    color: var(--color-nazored);
    @include fontsize(18);
    font-weight: 400;
    line-height: 2;
  }

  &__price {

    .indentlist+.summarylist__text {
      margin-top: 1.5em;
    }

  }

  &__detail {
    margin-top: 24px;
    border-top: 1px solid var(--color-gray1);
    border-bottom: 1px solid var(--color-gray1);
    box-shadow: var(--shadow-default);

    &--label {}

    &--trigger {
      width: 100%;
      background: none;
      padding: 18px 64px 18px 24px;
      background-color: var(--color-white);
      text-align: left;
      transition: background-color .3s var(--easing-cubic1), color .3s var(--easing-cubic1);
      position: relative;

      @include minmq($lg) {
        padding: 32px 96px 32px 40px;
      }

      .is-label {
        text-align: justify;
        font-size: clamp-px(16px, 18px, 20px, $lg);
        font-weight: 500;
        line-height: 1.8;
      }

      .is-toggle {
        width: clamp-px(16px, 24px, 32px, $lg);
        height: clamp-px(16px, 24px, 32px, $lg);
        background-color: var(--color-nazored);
        border-radius: var(--border-circle);
        position: absolute;
        top: 24px;
        right: 24px;
        line-height: 1;
        transition: background-color .3s var(--easing-cubic1);

        @include minmq($lg) {
          top: 32px;
          right: 40px;
        }

        &::before,
        &::after {
          display: block;
          content: "";
          width: clamp-px(10px, 14px, 18px, $lg);
          height: clamp-px(2px, 2px, 3px, $lg);
          background-color: var(--color-white);
          border-radius: var(--border-halfcircle);
          translate: 0 -50%;
          position: absolute;
          top: 50%;
          left: 50%;
          translate: -50% -50%;
          transition: background-color .3s var(--easing-cubic1), rotate .3s var(--easing-cubic1);
        }

        &::after {
          rotate: 90deg;
        }
      }

      &[aria-expanded="true"] {

        .is-toggle {
          background-color: var(--color-keynavy);

          &::after {
            rotate: 0deg;
          }
        }
      }

      @include hover {
        color: var(--color-nazored);
        // background-color: var(--color-gray5);
      }
    }

    &--desc {
      display: none;
      width: 100%;
      padding: 24px;
      background-color: var(--color-bgbox);
      text-align: left;
      position: relative;

      @include minmq($lg) {
        padding: 40px;
      }

      &[aria-hidden="false"] {
        display: flex;
        flex-direction: column;
        gap: clamp-px(32px, 36px, 40px, $lg);
        animation: inviewdown .4s var(--easing-cubic1) forwards;

        @include minmq($lg) {
          flex-direction: row;
        }

        &.is-block {
          display: block;
        }
      }
    }

    &--inner {
      flex: 0 1 auto;
    }

    &--text {
      font-size: clamp-px(15px, 18px, 18px, $lg);
      font-weight: 400;
      line-height: 1.8;

      &+& {
        margin-top: 1em;
      }

      &+.title__style3 {
        margin-top: 32px;
      }
    }

    &--sample {
      flex: 0 0 auto;
      max-width: 362px;
      margin-left: auto;
      margin-right: auto;

      img {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &--caption {
      display: flex;
      justify-content: center;
      margin-top: 8px;

      @include minmq($lg) {
        display: block;
      }
    }

  }

  .summarylist.is-org {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid var(--color-gray4);

    @include minmq($md) {
      margin-top: 36px;
      padding-top: 36px;
    }
  }

  .summarylist__body {
    .is-mgn {
      margin-top: 1.25em;
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 40px;

    @include minmq($lg) {
      margin-bottom: 56px;
    }

    &.is-mgn {
      margin-top: 40px;

      @include minmq($lg) {
        margin-top: 56px;
      }

    }

    &--th {
      width: 64px;
      padding: 16px 8px;
      color: var(--color-keynavy);
      @include fontsize(15);
      font-weight: 500;
      line-height: 1.4;
      text-align: center;
      background-color: var(--color-bgbox);
      border-top: 1px solid var(--color-gray4);
      border-bottom: 1px solid var(--color-gray4);

      @include minmq($md) {
        @include fontsize(16);
        width: 180px;
        padding: 16px;
      }

    }

    &--td {
      padding: 16px;
      background-color: var(--color-white);
      border-top: 1px solid var(--color-gray4);
      border-bottom: 1px solid var(--color-gray4);
    }
  }


  &__price {
    margin-top: 40px;
    margin-bottom: 40px;

    &--tablist {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 8px;
    }

    &--tabitem {
      flex: 0 1 auto;
    }

    &--trigger {
      width: 100%;
      height: 70px;
      padding: 16px 10px 10px;
      color: var(--color-white);
      @include fontsize(15);
      font-weight: 500;
      line-height: 1.4;
      text-align: center;
      background-color: var(--color-gray1);
      border-bottom: 2px solid var(--color-white);
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      @include minmq(360px) {
        width: 160px;
      }

      &[aria-selected="true"] {
        height: 74px;
        color: var(--color-nazored);
        font-weight: 600;
        background-color: var(--color-gray5);
        border-bottom-color: var(--color-gray5);
        border-top: 6px solid var(--color-nazored);
      }

      @include minmq($md) {
        @include fontsize(17);
        width: 346px;
        height: 56px;

        &[aria-selected="true"] {
          height: 64px;
        }
      }

    }

    &--contents {
      display: none;
      padding: 8px;
      background-color: var(--color-gray5);

      &[aria-hidden="false"] {
        display: flex;
        gap: 2px;
        animation: inviewfadein .4s var(--easing-cubic1) forwards;
      }

      &#firsttime {
        &[aria-hidden="false"] {
          display: grid;
          grid-template-columns: 1fr;
          gap: 0;
        }
      }

      @include minmq($md) {
        padding: 24px;

        &[aria-hidden="false"] {
          gap: 8px;
        }

        &#firsttime {
          &[aria-hidden="false"] {
            gap: 0;
          }
        }
      }

    }

    &--block {

      &.is-early {
        flex: 0 1 100%;

        .guide__price--tbllabel {
          margin-left: calc(45px + 8px);
        }

        @include minmq($md) {
          .guide__price--tbllabel {
            margin-left: 0;
          }
        }
      }

      &.is-general {
        flex: 0 0 auto;
        width: 110px;

        @include minmq(360px) {
          flex: 0 1 calc(100% - (45px + 8px));
          min-width: 129px;
        }

        .guide__price--th {
          display: none;
        }

        tr:nth-child(2) {
          td {
            height: 72px;
          }
        }

        tr:nth-child(3) {
          td {
            height: 111px;
          }
        }

        @include minmq($md) {
          flex: 0 1 100%;
          width: auto;

          .guide__price--th {
            display: table-cell;
          }

          tr:nth-child(2) {
            td {
              height: auto;
            }
          }

          tr:nth-child(3) {
            td {
              height: auto;
            }
          }
        }
      }
    }

    &--tbllabel {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      color: var(--color-white);
      @include fontsize(14);
      font-weight: 500;
      line-height: 1.8;
      text-align: center;
      background-color: var(--color-keynavy);

      @include minmq($md) {
        @include fontsize(18);
      }
    }

    &--inner {
      padding: 2px 4px;
      background-color: var(--color-white);
    }

    &--table {
      width: 100%;
      border-collapse: collapse;

      tr {
        &:not(:first-child) {
          border-top: 1px solid var(--color-gray5);
        }
      }
    }

    &--th {
      width: 45px;
      padding: 16px 8px;
      @include fontsize(14);
      font-weight: 500;
      line-height: 1.4;
      text-align: center;
      background-color: var(--color-bgbox);
      position: relative;

      @include minmq($md) {
        @include fontsize(16);
        width: 82px;
      }

      &::before {
        display: block;
        content: '';
        width: 100%;
        height: 0;
        border-top: 2px solid var(--color-white);
        position: absolute;
        top: 0;
        left: 0;
      }

      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 0;
        border-bottom: 2px solid var(--color-white);
        position: absolute;
        bottom: 0;
        left: 0;
      }

      @include minmq($md) {
        @include fontsize(16);
        width: 82px;
      }
    }

    &--td {
      padding: 16px 0 16px 4px;

      @include minmq($md) {
        padding: 16px;
      }
    }

    &--text {
      @include fontsize(14);
      font-weight: 500;
      line-height: 1.8;
      text-align: center;

      &.is-small {
        @include fontsize(12);

        @include minmq($xs) {
          @include fontsize(13);
        }
      }

      @include minmq($md) {
        @include fontsize(16);
        text-align: left;

        &.is-small {
          @include fontsize(16);
        }
      }

    }
  }


}


.flow {
  .indentlist {
    margin-top: 10px;
  }

  &__section {
    position: relative;

    &:not(:last-of-type) {
      padding-bottom: 24px;

      @include minmq($md) {
        padding-bottom: 64px;
      }
    }

    &::before {
      display: block;
      content: '';
      width: 49px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      pointer-events: none;

      @include minmq($md) {
        width: 198px;
        left: 50px;
      }
    }

    &.is-1st {
      .flow__title {
        top: 18px;
      }

      &::before {
        background: url('/assets/img/flow/bg-flow1_sp.svg') bottom no-repeat #1F4795;
        background-size: 100% auto;
      }

      @include minmq($md) {
        .flow__title {
          top: 18px;
        }

        &::before {
          background: url('/assets/img/flow/bg-flow1_pc.svg') bottom no-repeat #1F4795;
          background-size: 100% auto;
          z-index: 5;
        }

        .is-flow1 {
          margin-top: 0;
        }

      }
    }

    &.is-2nd {
      &::before {
        background: url('/assets/img/flow/bg-flow2_sp.svg') bottom no-repeat var(--color-keynavy);
        background-size: 100% auto;
      }

      @include minmq($md) {
        .flow__title {
          top: 18px;
        }

        &::before {
          background: url('/assets/img/flow/bg-flow2_pc.svg') bottom no-repeat var(--color-keynavy);
          background-size: 100% auto;
          z-index: 3;
        }
      }
    }

    &.is-3rd {
      &::before {
        background-color: var(--color-keynavy);
      }

      @include minmq($md) {
        .flow__title {
          top: 18px;
        }
      }
    }
  }

  &__title {
    width: 49px;
    color: var(--color-white);
    font-size: clamp-px(14px, 18px, 24px, $lg);
    font-weight: 500;
    line-height: 1.3;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;

    @include minmq($md) {
      width: 198px;
      left: 50px;
    }
  }

  &__block {
    padding: 24px 24px 24px 72px;
    background-color: var(--color-bgbox);
    border-radius: var(--border-radius);
    position: relative;

    @include minmq($md) {
      margin-top: -24px;
      padding: 40px 40px 40px 300px;
    }

    &+& {
      margin-top: 24px;

      @include minmq($md) {
        margin-top: 40px;
      }
    }

    &--label {
      font-size: clamp-px(20px, 24px, 28px, $lg);
      font-weight: 500;
      line-height: 1.8;
    }

    &--text {
      font-size: clamp-px(15px, 18px, 18px, $lg);
      font-weight: 400;
      line-height: 1.8;
      margin-top: 10px;

      @include minmq($md) {
        margin-top: 16px;
      }
    }

    &--reportcard {
      margin-top: 16px;
    }

    // アイコン
    &::before {
      display: block;
      content: '';
      position: absolute;
      z-index: 8;
    }

    &.is-flow1 {
      &::before {
        width: 28px;
        height: 22px;
        background: url('/assets/img/flow/icon-flow1_sp.svg') bottom no-repeat;
        background-size: contain;
        top: 74px;
        left: 11px;

        @include minmq($md) {
          width: 198px;
          height: 52px;
          background: url('/assets/img/flow/icon-flow1_pc.svg') center bottom no-repeat;
          background-size: contain;
          top: 114px;
          left: 50px;
        }
      }
    }

    &.is-flow2 {
      &::before {
        width: 28px;
        height: 21px;
        background: url('/assets/img/flow/icon-flow2_sp.svg') bottom no-repeat;
        background-size: contain;
        position: absolute;
        top: 32px;
        left: 11px;

        @include minmq($md) {
          width: 198px;
          height: 52px;
          background: url('/assets/img/flow/icon-flow2_pc.svg') center bottom no-repeat;
          background-size: contain;
          top: 54px;
          left: 50px;
        }
      }
    }

    &.is-flow3 {
      &::before {
        width: 70px;
        height: 43px;
        background: url('/assets/img/flow/icon-flow3_sp.svg') bottom no-repeat;
        background-size: contain;
        position: absolute;
        top: 14px;
        left: -11px;

        @include minmq($md) {
          width: 198px;
          height: 80px;
          background: url('/assets/img/flow/icon-flow3_pc.svg') center bottom no-repeat;
          background-size: contain;
          top: 24px;
          left: 50px;
        }
      }
    }

    &.is-flow4 {
      &::before {
        width: 48px;
        height: 37px;
        background: url('/assets/img/flow/icon-flow4_sp.svg') bottom no-repeat;
        background-size: contain;
        position: absolute;
        top: 40px;
        left: 1px;

        @include minmq($md) {
          width: 198px;
          height: 78px;
          background: url('/assets/img/flow/icon-flow4_pc.svg') center bottom no-repeat;
          background-size: contain;
          top: 56px;
          left: 50px;
        }
      }
    }

    &.is-flow5 {
      &::before {
        width: 44px;
        height: 27px;
        background: url('/assets/img/flow/icon-flow5_sp.svg') bottom no-repeat;
        background-size: contain;
        position: absolute;
        top: 30px;
        left: 2px;

        @include minmq($md) {
          width: 198px;
          height: 50px;
          background: url('/assets/img/flow/icon-flow5_pc.svg') center bottom no-repeat;
          background-size: contain;
          top: 40px;
          left: 50px;
        }
      }
    }

    &.is-flow6 {
      &::before {
        width: 42px;
        height: 39px;
        background: url('/assets/img/flow/icon-flow6_sp.svg') bottom no-repeat;
        background-size: contain;
        position: absolute;
        top: 60px;
        left: 4px;

        @include minmq($md) {
          width: 198px;
          height: 78px;
          background: url('/assets/img/flow/icon-flow6_pc.svg') center bottom no-repeat;
          background-size: contain;
          top: 74px;
          left: 50px;
        }
      }
    }

    &.is-flow7 {
      &::before {
        width: 42px;
        height: 56px;
        background: url('/assets/img/flow/icon-flow7_sp.svg') bottom no-repeat;
        background-size: contain;
        position: absolute;
        top: 16px;
        left: 4px;

        @include minmq($md) {
          width: 198px;
          height: 92px;
          background: url('/assets/img/flow/icon-flow7_pc.svg') center bottom no-repeat;
          background-size: contain;
          top: 16px;
          left: 50px;
        }
      }
    }
  }
}


.exam-day {
  &__section {
    margin-top: 56px;

    @include minmq($lg) {
      margin-top: 120px;
    }
  }

  #required {
    .indentlist {
      margin-top: 8px;
    }
  }

  #check {
    .indentlist {
      margin-top: 8px;
      color: var(--color-gray2);
    }
  }
}

.apply {
  &__section {
    margin-top: 56px;

    @include minmq($lg) {
      margin-top: 120px;
    }

    .summarylist {
      &__item {
        @include minmq($lg) {
          grid-template-columns: 272px 1fr;
        }
      }
    }

    .indentlist {
      margin-top: 8px;
    }
  }

  &__step {
    display: grid;
    grid-template-columns: 1fr;
    gap: 96px;
    margin-top: 56px;

    @include minmq($md) {
      margin-top: 72px;
      gap: 56px;
      grid-template-columns: 292px 1fr;
    }

    &--item {
      padding: 40px 32px;
      background-color: var(--color-bgbox);
      border-radius: var(--border-radius);
      position: relative;

      @include minmq($md) {
        display: flex;
        flex-direction: column;
        padding: 64px 24px 56px;
      }

      @include minmq($lg) {
        padding: 64px 40px 56px;
      }

      &:first-child {
        &::after {
          display: block;
          content: '';
          width: 38px;
          height: 76px;
          background: url('/assets/img/common/arrow-step_right.svg') no-repeat;
          background-size: contain;
          position: absolute;
          top: calc(100% - 16px);
          left: 50%;
          rotate: 90deg;
          translate: -50% 0;

          @include minmq($md) {
            rotate: 0deg;
            top: 50%;
            left: calc(100% - -8px);
            translate: 0 -50%;
          }
        }

      }
    }

    &--title {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      font-family: var(--font-satoshi);
      @include fontsize(14);
      font-weight: 900;
      line-height: 1;
      color: var(--color-white);
      background-color: var(--color-keynavy);
      border-radius: var(--border-circle);
      position: absolute;
      top: 0;
      left: 50%;
      translate: -50% -50%;

      @include minmq($md) {
        width: 76px;
        height: 76px;
        @include fontsize(18);
      }

      @include minmq($lg) {
        width: 88px;
        height: 88px;
        @include fontsize(20);
      }
    }

    &--block {
      img {
        margin-left: auto;
        margin-right: auto;
      }

      .indentlist {
        display: flex;
        justify-content: center;
        @include fontsize(14);
        margin-top: 8px;
      }
    }

    &--btn {
      display: flex;
      justify-content: center;
      margin-top: 24px;

      &.is-cta {
        flex-direction: column;
        align-items: center;
        gap: 16px 24px;

        @include minmq($lg) {
          flex-direction: row;
        }

      }

      .btn__primary,
      .btn__secondary {
        min-height: 56px;
      }

      .btn__secondary {
        .is-label {
          @include fontsize(15);
        }
      }

      @include minmq($md) {
        padding-top: 24px;

        &.is-cta {
          grid-template-columns: 1fr 1fr;
        }
      }

      @include minmq($lg) {
        margin-top: auto;
      }
    }

    &--text {
      display: flex;
      justify-content: center;
      font-size: clamp-px(16px, 18px, 18px, $lg);
      font-weight: 500;
      line-height: 1.8;
      margin-top: 16px;
    }

  }

  &__creditcard {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 24px;
    margin-bottom: 24px;

    img {
      flex: 0 1 auto;
      max-width: calc(20% - 12px);
    }
  }

  &__store {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 24px;
    margin-bottom: 24px;

    img {
      flex: 0 1 auto;
    }
  }

  &__howtostore {
    &--title {
      @include fontsize(16);
      font-weight: 700;
      line-height: 1.8;
      margin-bottom: 8px;
    }

    &--store {
      margin-top: 4px;

      a {
        display: inline-flex;
        gap: 4px;
        @include fontsize(14);
        font-weight: 400;
        line-height: 1.8;

        &[target="_blank"] {
          &::after {
            display: block;
            content: "";
            width: 16px;
            height: 16px;
            mask-image: url('/assets/img/common/icon-blank.svg');
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;
            background: var(--color-nazored);
            transition: background-color .3s var(--easing-cubic1);
            translate: 0 4px;
          }
        }

        @include hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__box {
    margin-top: 32px;
    padding: 32px;
    background-color: var(--color-bgbox);
    border-radius: var(--border-radius);

    @include minmq($md) {
      margin-top: 56px;
    }

    &--notice {
      color: var(--color-nazored);
      @include fontsize(14);
      font-weight: 400;
      line-height: 2;

      @include minmq($lg) {
        @include fontsize(16);
      }

    }
  }

  .btnbox {
    margin-top: 80px;

    @include minmq($lg) {
      margin-top: 120px;
    }

  }
}