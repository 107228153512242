@import 'function/var';

.contact {

  &__box {
    padding: 24px 24px 40px;
    text-align: center;
    background-color: var(--color-bgbox);
    border-radius: var(--border-radius);

    &--title {
      color: var(--color-keynavy);
      @include fontsize(24);
      font-weight: 500;
      line-height: 1.8;
      margin-bottom: 16px;
    }

    &--address {
      display: flex;
      flex-direction: column;
      color: var(--color-keynavy);
      @include fontsize(16);
      font-weight: 400;
      font-style: normal;
      line-height: 1.8;
      margin-bottom: 24px;
    }

    &--btn {
      display: flex;
      justify-content: center;

      .is-label {
        @include fontsize(16);
      }
    }

    @include minmq($lg) {
      padding: 56px 56px 72px;

      &--title {}

      &--address {
        justify-content: center;
        flex-direction: row;
        gap: 1rem;
      }

      &--btn {
        .is-label {
          @include fontsize(16);
        }
      }
    }
  }
}