@import 'function/var';

html {
  font-family: var(--font-notosansjp);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;

  &.is-gnavshow {
    height: 100vh;
    overflow: hidden;
  }

}

body {
  color: var(--color-txtbase);
  font-feature-settings: "palt" 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba($color: #000, $alpha: .8);
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s var(--easing-cubic1);

    .is-gnavshow & {
      opacity: 1;
      pointer-events: all;
    }
  }
}


/* a link
--------------------------------------------- */
a {
  color: var(--color-link);
  text-decoration: none;
}