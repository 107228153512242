@import 'function/var';

.checklist {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;

  &__item {
    @include fontsize(18);
    line-height: 1.8;
    font-weight: 500;
    padding-left: 24px;
    position: relative;

    &::before {
      display: block;
      content: "";
      width: 16px;
      height: 16px;
      mask-image: url('/assets/img/common/icon-check.svg');
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      background: var(--color-nazored);
      position: absolute;
      top: 9px;
      left: 0;
    }
  }
}

.indentlist {
  &__item {
    text-indent: -1em;
    padding-left: 1em;
    font-weight: 400;

    &+& {
      margin-top: 4px;
    }

  }

  &.is-notice {
    .indentlist__item {
      // color: var(--color-gray2);
      font-size: clamp-px(12px, 14px, 14px, $lg);
    }
  }
}

.disclist {
  margin-left: 1.5em;

  &__item {
    list-style: disc;
    font-weight: 400;
    line-height: 2;

    &+& {
      margin-top: 4px;
    }
  }

  &__textlink {
    display: inline-flex;
    gap: 4px;
    margin-left: 2px;
    margin-right: 2px;
    color: var(--color-keynavy);
    text-decoration: underline;
    text-underline-offset: 2px;
    transition: color .3s var(--easing-cubic1);

    &[target="_blank"] {
      &::after {
        display: block;
        content: "";
        width: 16px;
        height: 16px;
        mask-image: url('/assets/img/common/icon-blank.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: var(--color-keynavy);
        transition: background-color .3s var(--easing-cubic1);
        translate: 0 5px;
      }
    }

    @include hover {
      // color: var(--color-nazored);
      text-decoration: none;
    }
  }
}

.numlist {
  margin-left: 1.5em;

  &__item {
    list-style: decimal;
    font-weight: 400;
    line-height: 2;

    &+& {
      margin-top: 4px;
    }
  }
}

// リンクリスト
.linklist {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;

  &__item {

    a {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      @include fontsize(14);
      color: var(--color-keynavy);
      font-weight: 500;
      line-height: 1.8;
      transition: color .3s var(--easing-cubic1);

      &::after {
        display: block;
        content: "";
        width: 4px;
        height: 7px;
        mask-image: url('/assets/img/common/icon-arrow2_right.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: var(--color-keynavy);
        transition: translate .3s var(--easing-cubic1);
      }

      @include hover {
        color: var(--color-nazored);

        &::after {
          translate: 5px 0;
          background: var(--color-nazored);
        }

      }
    }
  }
}


// タグリスト
.taglist {
  display: flex;
  flex-wrap: wrap;
  // grid-template-columns: repeat(auto-fill, minmax(min(65px, 100%), 1fr));
  gap: 8px;
  margin-bottom: 56px;

  // @include minmq($md) {
  //   display: flex;
  //   flex-wrap: wrap;
  // }

  @include minmq($lg) {
    margin-bottom: 120px;
  }

  &__item {
    display: inline-flex;
    flex: 0 0 auto;
  }

  &__link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 8px;
    padding: 5px 15px;
    color: var(--color-txtlink);
    @include fontsize(14);
    font-weight: 500;
    background-color: var(--color-gray5);
    border-radius: var(--border-halfcircle);
    transition: background-color .3s var(--easing-cubic1), color .3s var(--easing-cubic1);

    @include minmq($md) {
      padding-left: 24px;
      padding-right: 24px;
    }

    &[aria-current="page"] {
      color: var(--color-white);
      background-color: var(--color-keynavy);
    }

    @include hover {
      color: var(--color-white);
      background-color: var(--color-keynavy);
    }
  }
}

// アンカーリンク
.ancarlist {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 32px;

  @include minmq($md) {
    margin-bottom: 56px;
  }

  &__item {
    display: inline-flex;
    flex: 0 0 auto;
  }

  &__link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 6px 16px;
    color: var(--color-txtlink);
    font-size: clamp-px(12px, 15px, 14px, $lg);
    text-align: left;
    font-weight: 500;
    border: 1px solid var(--color-keynavy);
    border-radius: 4px;
    transition: color .3s var(--easing-cubic1);

    &::after {
      flex: 0 0 auto;
      display: block;
      content: "";
      width: 7px;
      height: 4px;
      mask-image: url('/assets/img/common/icon-arrow2_bottom.svg');
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      background: currentColor;
      transition: translate .3s var(--easing-cubic1), background-color .3s var(--easing-cubic1);
    }

    @include hover {
      color: var(--color-nazored);

      &::after {
        translate: 0 2px;
        background: var(--color-nazored);
      }
    }
  }
}


// パンくずリスト
.breadcrumbs {
  padding: 8px 16px;
  background-color: #EEEEF5;
  position: relative;
  z-index: 1;
  overflow-x: auto;

  // スクロールバーは非表示に
  /* IE, Edge 対応 */
  -ms-overflow-style: none;
  /* Firefox 対応 */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include minmq($xs) {
    padding-left: 24px;
    padding-right: 24px;
  }

  &__list {
    display: flex;
    gap: 6px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  &__item {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 0 6px;
    color: var(--color-gray3);
    font-size: clamp-px(10px, 11px, 12px, $lg);
    font-weight: 400;

    &:not(:last-child) {

      &::after {
        display: block;
        content: '/';
        color: var(--color-gray3);
      }
    }

    &:last-child {
      padding-right: 16px;

      @include minmq($xs) {
        padding-right: 24px;
      }

    }
  }

  &__link {
    flex: 0 0 auto;
    color: var(--color-txtlink);
    text-decoration: underline;

    @include hover {
      text-decoration: none;
    }
  }
}


// 概要リスト
.summarylist {

  &.is-mgn {
    margin-top: 56px;
  }

  &__item {
    .summarylist__body {
      padding-bottom: 24px;
    }

    &:not(:first-child) {

      .summarylist__head {
        padding-top: 24px;
      }

    }

    @include minmq($lg) {
      display: grid;
      grid-template-columns: 180px 1fr;

      .summarylist__body {
        padding-bottom: 36px;
      }

      &:not(:first-child) {

        .summarylist__head,
        .summarylist__body {
          padding-top: 36px;
        }
      }
    }
  }

  &__head {
    position: relative;

    @include minmq($lg) {
      padding-right: 16px;
      border-bottom: 1px solid var(--color-gray3);
    }
  }

  &__body {
    border-bottom: 1px solid var(--color-gray4);

    @include minmq($lg) {
      padding-left: 16px;
    }
  }

  &__label {
    @include fontsize(16);
    font-weight: 700;
    line-height: 1.8;
    margin-bottom: 24px;

    @include minmq($lg) {
      margin-bottom: 0;
    }
  }

  &__text {
    @include fontsize(16);
    font-weight: 400;
    line-height: 1.8;

    &.is-small {
      @include fontsize(14);
    }

    &+& {
      margin-top: 1em;
    }
  }

  &__textlink {
    display: inline-flex;
    gap: 4px;
    margin-left: 2px;
    margin-right: 2px;
    color: var(--color-keynavy);
    text-decoration: underline;
    text-underline-offset: 2px;
    transition: color .3s var(--easing-cubic1);

    &[target="_blank"] {
      &::after {
        display: block;
        content: "";
        width: 16px;
        height: 16px;
        mask-image: url('/assets/img/common/icon-blank.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: var(--color-keynavy);
        transition: background-color .3s var(--easing-cubic1);
        translate: 0 5px;
      }
    }

    @include hover {
      // color: var(--color-nazored);
      text-decoration: none;
    }
  }
}