@import 'function/var';

.btn {

  &__primary {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: 320px;
    width: 100%;
    color: var(--color-white);
    background-color: var(--color-btn-primary);
    border: 2px solid var(--color-btn-primary);
    border-radius: var(--border-halfcircle);
    font-size: clamp-px(24px, 26px, 28px, $lg);
    font-weight: 700;
    line-height: 1.4;
    padding: 24px;
    box-shadow: var(--shadow-btn-default);
    transition: box-shadow .15s var(--easing-cubic1), translate .15s var(--easing-cubic1);
    position: relative;

    &.is-small {
      @include fontsize(15);
      padding: 12px 16px;
    }

    @include hover {
      translate: 0 2px;
      box-shadow: 0 4px 0 rgba(23 27 60 / .13);
    }
  }

  &__secondary {
    display: flex;
    align-items: center;
    word-break: break-all;
    max-width: 320px;
    width: 100%;
    min-height: clamp-px(56px, 58px, 60px, $lg);
    gap: clamp-px(8px, 12px, 12px, $lg);
    padding: 8px clamp-px(16px, 18px, 20px, $lg);
    color: var(--color-keynavy);
    border: 2px solid var(--color-btn-line);
    border-radius: var(--border-halfcircle);
    background-color: var(--color-btn-secondary);
    box-shadow: var(--shadow-btn-default);
    transition: color .3s var(--easing-cubic1);
    position: relative;

    &.is-noicon {
      justify-content: center;
      transition: color .3s var(--easing-cubic1), box-shadow .15s var(--easing-cubic1), translate .15s var(--easing-cubic1);

      &::before,
      &::after {
        display: none !important;
      }
    }

    @include hover {
      color: var(--color-nazored);

      &::before {
        background: var(--color-nazored);
      }

      &::after {
        translate: 5px -50%;
        background: var(--color-nazored);
      }

      &[target="_blank"] {
        translate: 0 2px;
        box-shadow: 0 4px 0 rgba(23 27 60 / .13);

        &::after {
          translate: 0 -50%;
        }
      }

      &.is-noicon {
        translate: 0 2px;
        box-shadow: 0 4px 0 rgba(23 27 60 / .13);
      }
    }

    &::after {
      display: block;
      content: "";
      width: 6px;
      height: 12px;
      mask-image: url('/assets/img/common/icon-arrow2_right.svg');
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      background: currentColor;
      position: absolute;
      top: 52%;
      right: 24px;
      translate: 0 -50%;
      transition: translate .3s var(--easing-cubic1);
    }

    &[target="_blank"] {
      transition: color .3s var(--easing-cubic1), box-shadow .15s var(--easing-cubic1), translate .15s var(--easing-cubic1);

      &::after {
        display: block;
        content: "";
        width: 16px;
        height: 16px;
        mask-image: url('/assets/img/common/icon-blank.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: var(--color-keynavy);
        position: absolute;
        top: 52%;
        right: 20px;
        translate: 0 -50%;
        transition: background-color .3s var(--easing-cubic1);
      }
    }

    .is-label {
      display: inline-block;
      font-size: clamp-px(16px, 18px, 18px, $lg);
      text-align: left;
      font-weight: 500;
      line-height: 1.4;
    }

    .is-small {
      display: inline-block;
      line-height: 1;
      font-size: clamp-px(10px, 12px, 12px, $lg);
    }

    &.is-mail {
      justify-content: space-around;

      &::after {
        display: none;
      }

      &::before {
        flex: 0 0 auto;
        display: block;
        content: '';
        width: 24px;
        height: 24px;
        mask-image: url('/assets/img/common/icon-mail.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: currentColor;
        transition: background-color .3s var(--easing-cubic1);
      }
    }

    &.is-iconabout {
      &::before {
        flex: 0 0 auto;
        display: block;
        content: '';
        width: 24px;
        height: 24px;
        mask-image: url('/assets/img/common/icon-about.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: currentColor;
        transition: background-color .3s var(--easing-cubic1);
      }
    }

    &.is-iconguide {
      &::before {
        flex: 0 0 auto;
        display: block;
        content: '';
        width: 24px;
        height: 24px;
        mask-image: url('/assets/img/common/icon-guide.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: currentColor;
        transition: background-color .3s var(--easing-cubic1);
      }

    }

    &.is-icondata {
      &::before {
        flex: 0 0 auto;
        display: block;
        content: '';
        width: 24px;
        height: 24px;
        mask-image: url('/assets/img/common/icon-data.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: currentColor;
        transition: background-color .3s var(--easing-cubic1);
      }
    }

    &.is-iconvoice {
      &::before {
        flex: 0 0 auto;
        display: block;
        content: '';
        width: 24px;
        height: 24px;
        mask-image: url('/assets/img/common/icon-voice.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: currentColor;
        transition: background-color .3s var(--easing-cubic1);
      }
    }

    &.is-iconinterview {
      padding-top: 0px;
      padding-bottom: 6px;

      &::before {
        flex: 0 0 auto;
        display: block;
        content: '';
        width: 24px;
        height: 24px;
        mask-image: url('/assets/img/common/icon-interview.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: currentColor;
        translate: 0 2px;
        transition: background-color .3s var(--easing-cubic1);
      }
    }

    &.is-iconflow {
      &::before {
        flex: 0 0 auto;
        display: block;
        content: '';
        width: 24px;
        height: 24px;
        mask-image: url('/assets/img/common/icon-flow.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: currentColor;
        transition: background-color .3s var(--easing-cubic1);
      }
    }

    &.is-icontheday {
      &::before {
        flex: 0 0 auto;
        display: block;
        content: '';
        width: 24px;
        height: 24px;
        mask-image: url('/assets/img/common/icon-theday.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: currentColor;
        transition: background-color .3s var(--easing-cubic1);
      }
    }

    &.is-iconapp {
      &::before {
        flex: 0 0 auto;
        display: block;
        content: '';
        width: 24px;
        height: 24px;
        mask-image: url('/assets/img/common/icon-app.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: currentColor;
        transition: background-color .3s var(--easing-cubic1);
      }
    }

    &.is-iconform {
      &::before {
        flex: 0 0 auto;
        display: block;
        content: '';
        width: 24px;
        height: 24px;
        mask-image: url('/assets/img/common/icon-form.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: currentColor;
        transition: background-color .3s var(--easing-cubic1);
      }
    }

    &.is-iconpayment {
      &::before {
        flex: 0 0 auto;
        display: block;
        content: '';
        width: 24px;
        height: 24px;
        mask-image: url('/assets/img/common/icon-payment.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: currentColor;
        transition: background-color .3s var(--easing-cubic1);
      }
    }
  }

  &__fixpagetop {
    display: inline-block;
    position: fixed;
    bottom: 16px;
    right: 8px;
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s var(--easing-cubic1);

    &.is-show {
      opacity: 1;
      pointer-events: all;
    }

    &.is-stay {
      position: absolute;
      bottom: auto;
      top: -20px;
    }

    img {
      filter: drop-shadow(0 6px 0 rgba(52 49 49 / .20));
      transition: filter .3s var(--easing-cubic1), translate .3s var(--easing-cubic1);
    }

    @include minmq($md) {
      bottom: 20px;
      right: 16px;
    }

    @include minmq($lg) {
      bottom: 24px;
      right: 24px;
    }

    @include hover {
      img {
        translate: 0 2px;
        filter: drop-shadow(0 2px 0 rgba(52 49 49 / .20));
      }
    }
  }

  &__fixbtn {
    width: 100px;
    height: 100px;
    position: fixed;
    bottom: 64px;
    right: 8px;
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s var(--easing-cubic1);

    .guide & {
      display: none;
    }

    .guide.itsudemo & {
      display: block;
    }

    @include minmq($md) {
      width: 144px;
      height: 144px;
      bottom: 80px;
      right: 16px;
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: var(--border-circle);
      box-shadow: var(--shadow-btn-default);
      transition: transform .3s var(--easing-cubic1);

      &::after {
        display: block;
        content: '';
        width: 100px;
        height: 100px;
        background: url('/assets/img/common/bnr-bgcircle.svg') no-repeat;
        background-size: contain;
        animation: fixRotate 10s linear infinite;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        pointer-events: none;

        @include minmq($md) {
          width: 144px;
          height: 144px;
        }

      }

      @include hover {
        transform: scale(1.1);

        // &::after {
        //   animation-play-state: paused;
        // }
      }
    }

    &--logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
      gap: 4px;
      position: relative;
      z-index: 1;

      @include minmq($md) {
        img {
          width: 62px;
          height: auto;
        }
      }

    }

    &--stxt {
      @include fontsize(10);
      font-weight: 700;
      line-height: 1.4;
      color: var(--color-white);
      position: relative;
      z-index: 1;

      @include minmq($md) {
        @include fontsize(14);
      }

    }

    &--label {
      @include fontsize(13);
      font-weight: 700;
      line-height: 1.4;
      color: var(--color-white);
      text-align: center;
      position: relative;
      z-index: 1;

      @include minmq($md) {
        @include fontsize(16);
      }

    }

    &.is-show {
      opacity: 1;
      pointer-events: all;

      &::after {
        rotate: 360deg;
      }
    }

  }
}

@keyframes fixRotate {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}


// CTAボタン郡
.btnbox {
  margin-top: 56px;
  padding: 24px 24px 32px;
  background-color: var(--color-bgbox);
  border-radius: var(--border-radius);

  @include minmq($xs) {
    padding: 24px 32px 32px;
  }

  @include minmq($md) {
    margin-top: clamp-px(64px, 80px, 80px, $lg);
    padding: clamp-px(40px, 56px, 56px, $lg) clamp-px(64px, 80px, 80px, $lg) clamp-px(64px, 80px, 80px, $lg);
  }

  &.is-sublist {
    margin-top: 56px;
    padding: 0;
    background: none;
    border-radius: none;
  }

  &__hgroup {
    display: grid;
    justify-content: center;
    text-align: center;
    gap: 8px;
    margin-bottom: 24px;


    .btnbox__ttl {
      margin-bottom: 0;
    }

    @include minmq($md) {
      margin-bottom: 32px;
    }
  }

  &__ttl {
    color: var(--color-keynavy);
    font-size: clamp-px(18px, 24px, 24px, $lg);
    font-weight: 500;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 24px;

    @include minmq($md) {
      margin-bottom: 32px;
    }
  }

  &__subttl {
    color: var(--color-keynavy);
    @include fontsize(18);
    font-weight: 500;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 16px;
  }

  &__notice {
    color: var(--color-keynavy);
    font-size: clamp-px(12px, 14px, 14px, $lg);
    font-weight: 500;
    line-height: 1.6;
    text-indent: -1em;
    padding-left: 1em;
    font-weight: 400;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    &.is-apply {
      .btnbox__link {
        padding: 24px 16px;
        min-height: 110px;

        &--inner {
          grid-template-rows: auto 1fr;
        }
      }
    }

    @include minmq($md) {
      display: flex;
      justify-content: center;
      gap: 24px;

      &.is-apply {
        display: grid;
        grid-template-columns: 1fr 1fr 152px;
      }

      &.is-apply2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .btnbox__applylink--label {
          @include fontsize(18);
        }

        .btnbox__applylink--inner {
          gap: 8px;
        }
      }
    }

    &.is-secondary {
      justify-items: center;

      @include minmq($md) {}
    }

    &+.btnbox__notice {
      margin-top: 24px;
      // text-align: center;

      @include minmq($md) {
        margin-top: 32px;
        text-align: center;
      }
    }
  }

  &__item {
    flex: 0 1 100%;
    position: relative;

    .is-secondary & {
      max-width: 320px;
      width: 100%;

      @include minmq($md) {
        flex: 0 1 320px;
      }
    }

    &.is-free {
      margin-top: 24px;

      @include minmq($md) {
        margin-top: 0;
      }

      &::before {
        display: grid;
        place-items: center;
        content: 'FREE';
        width: 56px;
        height: 56px;
        color: var(--color-white);
        @include fontsize(14);
        font-family: var(--font-satoshi);
        font-weight: 900;
        text-align: center;
        background-color: var(--color-keynavy);
        border: 2px solid var(--color-white);
        border-radius: var(--border-circle);
        position: absolute;
        top: 0;
        left: 50%;
        translate: -50% -50%;
        z-index: 1;
      }
    }
  }

  &__link {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    max-width: 348px;
    min-height: 150px;
    padding: 24px 16px;
    margin-left: auto;
    margin-right: auto;
    color: var(--color-keynavy);
    border: 2px solid var(--color-btn-line);
    border-radius: var(--border-radius);
    background-color: var(--color-btn-secondary);
    box-shadow: var(--shadow-btn-default);
    transition: box-shadow .15s var(--easing-cubic1), translate .15s var(--easing-cubic1);

    @include minmq($xs) {
      padding: 24px;
    }

    @include minmq($md) {
      min-height: 160px;
    }

    &--inner {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 68px 1fr;
      gap: 8px;
    }

    &--img {
      display: flex;
      justify-content: center;
    }

    &--stext {
      font-size: clamp-px(12px, 14px, 14px, $lg);
      font-weight: 500;
      line-height: 1.4;
      text-align: center;
    }

    &--label {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: clamp-px(16px, 20px, 20px, $lg);
      font-weight: 500;
      line-height: 1.4;
      text-align: center;

      &::after {
        display: block;
        content: "";
        width: 6px;
        height: 12px;
        mask-image: url('/assets/img/common/icon-arrow2_right.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: var(--color-keynavy);
        transition: translate .3s var(--easing-cubic1), background-color .3s var(--easing-cubic1);
        translate: 0 2px;
      }
    }

    @include hover {
      translate: 0 2px;
      box-shadow: 0 4px 0 rgba(23 27 60 / .13);

      .btnbox__link--stext {
        color: var(--color-nazored);
      }

      .btnbox__link--label {
        color: var(--color-nazored);

        &::after {
          translate: 5px 2px;
          background: var(--color-nazored);
        }
      }
    }

  }

  &__applylink {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    max-width: 348px;
    min-height: 110px;
    padding: 24px 16px;
    margin-left: auto;
    margin-right: auto;
    color: var(--color-white);
    border-radius: var(--border-radius);
    background-color: var(--color-btn-primary);
    box-shadow: var(--shadow-btn-default);
    transition: box-shadow .15s var(--easing-cubic1), translate .15s var(--easing-cubic1);

    @include minmq($md) {
      min-height: 146px;
    }

    &--inner {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 40px 1fr;
      gap: 8px;

      @include minmq($md) {
        gap: 14px;
      }
    }

    &--img {
      display: flex;
      justify-content: center;
    }

    &--stext {
      font-size: clamp-px(12px, 14px, 14px, $lg);
      font-weight: 500;
      line-height: 1.4;
      text-align: center;
    }

    &--label {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: clamp-px(16px, 20px, 20px, $lg);
      font-weight: 500;
      line-height: 1.4;
      text-align: center;

      &::after {
        display: block;
        content: "";
        width: 6px;
        height: 12px;
        mask-image: url('/assets/img/common/icon-arrow2_right.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: var(--color-white);
        transition: translate .3s var(--easing-cubic1), background-color .3s var(--easing-cubic1);
        translate: 0 2px;
      }
    }

    @include hover {
      translate: 0 2px;
      box-shadow: 0 4px 0 rgba(23 27 60 / .13);

      .btnbox__applylink--label {
        &::after {
          translate: 5px 2px;
          // background: var(--color-nazored);
        }
      }
    }

  }
}