@import 'function/var';

.home {

  // ファーストビュー
  &__kv {
    position: relative;
    // height: calc(100dvh - var(--header-height) - 62px);
    height: clamp-vw(426px, 320px);

    @include minmq($xs) {
      height: clamp-vw(496px, $xs);
    }

    @include minmq($sm) {
      // height: calc(100dvh - var(--header-height) - 80px);
      height: auto;
    }

    &--img {
      display: block;
      height: 100%;

      @include minmq($md) {
        height: auto;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;

        @include minmq($md) {
          height: auto;
          object-fit: contain;
        }
      }
    }

    &--scroll {
      display: none;

      @include minmq($lg) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        gap: 10px;
        position: absolute;
        left: 50%;
        bottom: 0;
        translate: -50% 0;

        .is-label {
          @include fontsize(14);
          @include kerning(100);
          font-family: var(--font-satoshi);
          font-weight: 400;
          color: var(--color-white);
          text-transform: uppercase;
        }

        &::after {
          display: block;
          content: '';
          width: 1px;
          height: clamp-px(72px, 96px, 104px, $xlg);
          margin-left: auto;
          margin-right: auto;
          background: linear-gradient(180deg, var(--color-white) 56.88%, var(--color-nazored) 91.59%);
          animation: scrolldown 2s var(--easing-cubic1) infinite;
        }
      }

    }
  }

  &__contsnav {
    display: flex;
    justify-content: center;
    gap: 8px;
    max-width: calc(100% - 32px);
    width: 100%;
    position: absolute;
    left: 50%;
    bottom: 0;
    translate: -50% 50%;
    z-index: 2;

    @include minmq($xs) {
      max-width: 327px;
    }

    @include minmq($md) {
      max-width: 576px;
      gap: clamp-px(32px, 56px, 96px, $lg);
    }

    @include minmq($lg) {
      max-width: 736px;
    }

    &--item {
      flex: 0 1 100%;
    }

    &--link {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 160px;
      min-height: 90px;
      padding: 12px 18px;
      background-color: var(--color-white);
      border-radius: var(--border-radius);
      box-shadow: var(--shadow-default);
      position: relative;
      z-index: 1;

      @include minmq($md) {
        max-width: 100%;
        min-height: 140px;
        padding: 24px;
      }

      @include minmq($lg) {
        max-width: 100%;
        min-height: 162px;
        padding: 24px;
      }

      &::after {
        display: block;
        content: '';
        width: 10px;
        height: 5px;
        mask-image: url('/assets/img/common/icon-arrow2_bottom.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: var(--color-nazored);
        position: absolute;
        left: 50%;
        bottom: 10px;
        translate: -50% 0;
        transition: translate .3s var(--easing-cubic1);

        @include minmq($md) {
          width: 15px;
          height: 7px;
        }

        @include minmq($lg) {
          bottom: 24px;
        }
      }

      @include hover {
        &::after {
          translate: -50% 6px;
        }
      }
    }
  }

  // セクション共通設定
  &__section {
    padding: 56px 16px;
    position: relative;

    @include minmq($xs) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @include minmq($lg) {
      padding-top: 120px;
      padding-bottom: 120px;
    }

    &::before {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: url('/assets/img/common/bg-texture1.png');
      opacity: .2;
      z-index: 0;
      pointer-events: none;
    }
  }

  &__container {
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
  }


  // タイトル共通設定
  &__hgroup {
    display: grid;
    grid-template-columns: 1fr;
    gap: clamp-px(32px, 40px, 40px, $md);
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    z-index: 1;

    .is-mainlabel {
      font-family: var(--font-satoshi);
      font-size: clamp-px(40px, 48px, 56px, $lg);
      font-weight: 900;
      line-height: 1.2;
      color: var(--color-keynavy);
      text-transform: uppercase;

      &::first-letter {
        color: var(--color-nazored);
      }
    }

    .is-sublabel {
      font-size: clamp-px(14px, 15px, 16px, $lg);
      font-weight: 500;
      line-height: 1.4;
      color: var(--color-keynavy);
    }
  }


  // テキスト共通設定
  &__text {
    @include fontsize(18);
    font-weight: 400;
    line-height: 2;
    color: var(--color-keynavy);
  }


  // ボタン共通設定
  &__action {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 8px;
    position: relative;
    z-index: 1;

    &--sublabel {
      display: inline-flex;
      align-items: baseline;
      gap: 12px;
      @include fontsize(20);
      font-weight: 700;
      color: var(--color-keynavy);

      &::before,
      &::after {
        display: block;
        content: '';
        width: 0;
        height: 72%;
        border-left: 3px solid currentColor;
        border-radius: var(--border-halfcircle);
        translate: 0 3px;
      }

      &::before {
        rotate: -30deg;
      }

      &::after {
        rotate: 30deg;
      }
    }

    &--btn {
      width: 100%;
    }
  }

  &__navlist {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }

  &__navitem {
    display: flex;
    justify-content: center;

    @include minmq($lg) {
      justify-content: flex-start;
    }
  }

  &__navlink {
    display: flex;
    align-items: center;
    max-width: 320px;
    width: 100%;
    min-height: clamp-px(56px, 58px, 60px, $lg);
    gap: clamp-px(8px, 12px, 12px, $lg);
    padding: 8px clamp-px(16px, 20px, 24px, $lg);
    color: var(--color-keynavy);
    border: 2px solid var(--color-btn-line);
    border-radius: var(--border-halfcircle);
    background-color: var(--color-btn-secondary);
    box-shadow: var(--shadow-btn-default);
    transition: color .3s var(--easing-cubic1);
    position: relative;

    @include hover {
      color: var(--color-nazored);

      &::before {
        background: var(--color-nazored);
      }

      &::after {
        translate: 5px -50%;
        background: var(--color-nazored);
      }
    }

    &::after {
      display: block;
      content: "";
      width: 6px;
      height: 12px;
      mask-image: url('/assets/img/common/icon-arrow2_right.svg');
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      background: currentColor;
      position: absolute;
      top: 52%;
      right: 24px;
      translate: 0 -50%;
      transition: translate .3s var(--easing-cubic1);
    }

    &::before {
      display: block;
      content: '';
      width: clamp-px(24px, 26px, 28px, $lg);
      height: clamp-px(24px, 26px, 28px, $lg);
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      background: currentColor;
      transition: background-color .3s var(--easing-cubic1);
    }

    .is-label {
      display: inline-block;
      font-size: clamp-px(16px, 18px, 18px, $lg);
      font-weight: 500;
      line-height: 1.4;
    }

    .is-small {
      display: inline-block;
      line-height: 1;
      font-size: clamp-px(10px, 12px, 12px, $lg);
    }

    &.is-iconabout {
      &::before {
        mask-image: url('/assets/img/common/icon-about.svg');
      }
    }

    &.is-iconguide {
      padding-top: 0;
      padding-bottom: 0;

      .is-label {
        translate: 0 -4px;
      }

      &::before {
        mask-image: url('/assets/img/common/icon-guide.svg');
      }
    }

    &.is-icondata {
      &::before {
        mask-image: url('/assets/img/common/icon-data.svg');
      }
    }

    &.is-iconvoice {
      &::before {
        mask-image: url('/assets/img/common/icon-voice.svg');
      }
    }

    &.is-iconinterview {
      padding-top: 0;
      padding-bottom: 0;

      .is-label {
        translate: 0 -4px;
      }

      &::before {
        mask-image: url('/assets/img/common/icon-interview.svg');
      }
    }

    &.is-iconflow {
      &::before {
        mask-image: url('/assets/img/common/icon-flow.svg');
      }
    }

    &.is-icontheday {
      &::before {
        mask-image: url('/assets/img/common/icon-theday.svg');
      }
    }

    &.is-iconapp {
      &::before {
        mask-image: url('/assets/img/common/icon-app.svg');
      }
    }

  }

  // PICK UP
  &__pickup {
    padding: 56px 0 0;
    background-color: var(--color-keynavy);
    position: relative;

    &::before {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: url('/assets/img/common/bg-texture1.png');
      opacity: .25;
      z-index: 0;
      pointer-events: none;
    }

    @include minmq($md) {
      // padding: 152px 0 0;
    }

    .home__title {
      max-width: 1200px;
      padding-left: 16px;
      padding-right: 16px;
      margin-left: auto;
      margin-right: auto;

      .is-mainlabel,
      .is-sublabel {
        color: var(--color-white);
      }

      @include minmq($xs) {
        padding-left: 24px;
        padding-right: 24px;
      }

      @include minmq($md) {
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    &--container {
      overflow: hidden;
    }

    &--slider {
      margin-top: 32px;
      padding-bottom: 56px;
      overflow: visible;

      @include minmq($md) {
        padding-bottom: 120px;
      }

      .swiper-operation {
        margin-top: 32px;
      }

      .swiper-slide {
        max-width: calc(320px - 32px);
        opacity: 0 !important;
        overflow: visible;
        transform-origin: center max(1400px, 83.3333333333vw);

        &.swiper-slide-visible {
          opacity: 1 !important;
        }

        @include minmq($sm) {
          max-width: clamp-vw(340px, $sm);
        }

        @include minmq($md) {
          max-width: clamp-vw(340px, $md);
          // transform-origin: center max(1400px, 83.3333333333vw);
        }

        @include minmq($lg) {
          max-width: clamp-vw(340px, $lg);
        }

        @include minmq(1200px) {
          max-width: clamp-vw(340px, 1200px);
        }
      }

    }

    &--card {
      display: block;
      // max-width: calc(100% - 32px);
      margin-left: auto;
      margin-right: auto;
      padding: 16px;
      color: var(--color-keynavy);
      background-color: var(--color-white);
      border-radius: var(--border-radius);
      box-shadow: var(--shadow-btn-default);

      @include minmq($xs) {
        max-width: 340px;
        padding: 24px 16px;
      }

      // @include minmq(1200px) {
      //   max-width: 100%;
      //   width: 100%;
      // }

      @include hover {
        color: var(--color-nazored);

        .home__pickup--img {
          &::before {
            background-color: rgba(23 27 60 / .13);
          }
        }
      }
    }

    &--thumbnail {
      display: grid;
      gap: 16px;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      align-items: flex-start;
      margin-bottom: 0;
    }

    &--img {
      position: relative;

      img {
        width: 100%;
        height: auto;
      }

      &::before {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: transparent;
        transition: background-color .3s var(--easing-cubic1);
      }
    }

    &--posttitle {
      font-weight: 500;
      line-height: 1.6;
      @include fontsize(16);
      @include line-clamp(2);
      transition: color .3s var(--easing-cubic1);
    }

  }


  &__movie {

    .home__container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px;

      @include minmq($lg) {
        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
          'mvtitle mvemded'
          'mvbtn mvemded';

        .home__hgroup {
          grid-area: mvtitle;
        }

        .home__movie--link {
          grid-area: mvbtn;
        }
      }
    }

    &--embed {
      width: 100%;

      &>* {
        width: 100dvw;
        margin-left: -16px;
        margin-right: -16px;

        @include minmq($xs) {
          margin-left: -24px;
          margin-right: -24px;
        }

        @include minmq($sm) {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }

      @include minmq($lg) {
        grid-area: mvemded;
        max-width: 700px;
        margin-left: auto;
      }
    }
  }


  &__about {
    padding-top: 80px !important;
    background-color: #F1EEEE;

    @include minmq($md) {
      padding-top: 152px !important;
    }

    .home__container {
      position: relative;
      z-index: 1;

      @include minmq($lg) {
        display: grid;
        grid-template-columns: 1fr 656px;
      }
    }

    &--nav {
      margin-top: 32px;

      @include minmq($md) {
        .home__navlist {
          grid-template-columns: 1fr 1fr;

          .home__navlink {
            max-width: 100%;
          }
        }
      }

      @include minmq($lg) {
        margin-top: 0;

        .home__navlink {
          max-width: 320px;
        }
      }
    }

    &--bgobject1 {
      position: absolute;
      top: -36px;
      right: 0;
      z-index: 1;

      @include minmq($lg) {
        top: -85px;
        right: auto;
        left: 0;
      }
    }

    &--bgobject2 {
      position: absolute;
      bottom: -96px;
      right: 0;
      z-index: 1;

      @include minmq($xs) {
        bottom: -108px;
      }

      @include minmq($lg) {
        bottom: -130px;
      }
    }
  }

  &__challenge {
    .home__container {
      position: relative;
      z-index: 1;

      @include minmq($lg) {
        display: grid;
        gap: 40px;
        grid-template-columns: 1fr 629px;
        grid-template-rows: auto 1fr;
        grid-template-areas:
          'challengetitle challengeimg'
          'challengenav challengeimg'
          'challengelink challengelink';

        .home__hgroup {
          grid-area: challengetitle;
        }
      }
    }

    &--img {
      margin-top: 32px;

      @include minmq($xs) {
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }

      @include minmq($lg) {
        align-items: center;
        margin-top: 0;
        grid-area: challengeimg;
      }
    }

    &--nav {
      margin-top: 32px;

      @include minmq($md) {
        margin-top: 40px;

        .home__navlist {
          grid-template-columns: 1fr 1fr 1fr;
          justify-content: center;
        }

        .home__navlink {
          max-width: 100%;
        }
      }

      @include minmq($lg) {
        margin-top: 0;
        grid-area: challengenav;

        .home__navlist {
          grid-template-columns: 1fr;
        }

        .home__navlink {
          max-width: 320px;
        }
      }
    }

    &--link {
      margin-top: 32px;

      @include minmq($lg) {
        grid-area: challengelink;
        margin-top: 0;
      }
    }
  }

  &__practice {
    background-color: var(--color-keynavy);

    .home__hgroup {
      max-width: 1120px;
      margin-left: auto;
      margin-right: auto;

      .is-mainlabel,
      .is-sublabel {
        color: var(--color-white);
      }
    }

    .home__text {
      color: var(--color-white);
    }

    .home__container {
      max-width: 1200px;
      position: relative;
      z-index: 1;
    }

    &--nav {
      margin-top: 24px;

      @include minmq($lg) {
        margin-top: 40px;
      }
    }

    &--cardlist {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(min(288px, 100%), 1fr));
      gap: 24px 20px;

      @include minmq($md) {
        grid-template-columns: repeat(auto-fill, minmax(min(229px, 100%), 1fr));
      }
    }

    &--carditem {
      position: relative;

      &.is-free {
        margin-top: 24px;

        @include minmq($md) {
          margin-top: 0;
        }

        &::before {
          display: grid;
          place-items: center;
          content: 'FREE';
          width: 62px;
          height: 62px;
          color: var(--color-white);
          @include fontsize(16);
          font-family: var(--font-satoshi);
          font-weight: 900;
          text-align: center;
          background-color: var(--color-keynavy);
          border: 2px solid var(--color-white);
          border-radius: var(--border-circle);
          position: absolute;
          top: 0;
          left: 50%;
          translate: -50% -50%;
        }
      }
    }

    &--cardlink {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto 40px;
      gap: 24px;
      justify-items: center;
      align-content: space-between;
      height: 100%;
      background-color: var(--color-btn-secondary);
      border: 2px solid var(--color-btn-line);
      border-radius: var(--border-radius);
      overflow: hidden;

      @include hover {
        .home__practice--cardmore {
          background-color: var(--color-nazored);
          color: var(--color-white);
        }
      }
    }

    &--cardtitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 40px 24px 0;

      .is-sublabel {
        display: inline-flex;
        align-items: baseline;
        gap: 12px;
        @include fontsize(14);
        font-weight: 500;
        line-height: 1.2;
        text-align: center;
        color: var(--color-keynavy);

        @include minmq($md) {
          align-items: center;
        }

        &::before,
        &::after {
          display: block;
          content: '';
          width: 0;
          height: 100%;
          border-left: 2px solid currentColor;
          border-radius: var(--border-halfcircle);
          translate: 0 3px;

          @include minmq($md) {
            translate: 0 0;
          }

        }

        &::before {
          rotate: -30deg;
        }

        &::after {
          rotate: 30deg;
        }
      }

      .is-mainlabel {
        color: var(--color-keynavy);
        @include fontsize(24);
        font-weight: 700;
        line-height: 1.4;
        text-align: center;

        @include minmq($md) {
          translate: 0 14px;

          &.is-nolh {
            translate: 0 0;
          }
        }
      }
    }

    &--cardthumbnail {
      padding-left: 24px;
      padding-right: 24px;
      margin-bottom: 0;
    }

    &--cardmore {
      display: grid;
      place-items: center;
      width: 100%;
      @include fontsize(18);
      font-family: var(--font-satoshi);
      font-weight: 900;
      line-height: 1.4;
      text-align: center;
      color: var(--color-keynavy);
      background-color: #E1E4EA;
      transition: background-color .3s var(--easing-cubic1), color .3s var(--easing-cubic1);
    }
  }

  &__news {
    .home__container {
      display: grid;
      gap: clamp-px(40px, 40px, 80px, $lg);
      // grid-template-areas:
      //   'newstitle'
      //   'newsnav'
      //   'newsconts'
      //   'newsmore';
      grid-template-areas:
        'newstitle'
        'newsconts'
        'newsmore';
      // grid-template-columns: 1fr 92px;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto 1fr;
      position: relative;
      z-index: 1;

      @include minmq($lg) {
        // grid-template-areas:
        //   'newstitle newstitle'
        //   'newsnav newsconts'
        //   'newsmore newsconts';
        grid-template-areas:
          'newstitle'
          'newsconts'
          'newsmore';
        // grid-template-columns: auto 1fr;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
      }
    }

    &--link {
      display: flex;
      justify-content: center;
      padding-top: 6px;
      grid-area: newsmore;

      @include minmq($lg) {
        // justify-content: flex-start;
        // align-items: flex-end;
        padding-top: 0;
      }
    }

    &--btn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 180px;
      height: 44px;
      // font-size: clamp-px(12px, 13px, 14px, $lg);
      @include fontsize(14);
      font-weight: 500;
      text-align: center;
      background-color: var(--color-btn-secondary);
      border-radius: var(--border-halfcircle);
      border: 2px solid var(--color-btn-line);
      box-shadow: var(--shadow-btn-default);
      transition: color .3s var(--easing-cubic1), box-shadow .15s var(--easing-cubic1), translate .15s var(--easing-cubic1);

      @include hover {
        color: var(--color-nazored);
        translate: 0 2px;
        box-shadow: 0 4px 0 rgba(23 27 60 / .13);
      }

      // @include minmq($lg) {
      //   max-width: 104px;
      //   height: 44px;
      // }
    }

    &--nav {
      display: flex;
      justify-content: center;
      width: 100%;
      grid-area: newsnav;
      overflow-x: auto;

      @include minmq($lg) {
        translate: 0 -16px;
      }

      // スクロールバーは非表示に
      /* IE, Edge 対応 */
      -ms-overflow-style: none;
      /* Firefox 対応 */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

    }

    &--list {
      display: flex;

      @include minmq($lg) {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
      }
    }

    &--item {
      flex: 0 0 auto;
    }

    &--trigger {
      display: inline-flex;
      justify-content: center;
      color: var(--color-keynavy);
      @include fontsize(14);
      font-family: var(--font-satoshi);
      font-weight: 500;
      text-align: center;
      padding-left: 8px;
      padding-right: 8px;
      padding-bottom: 16px;
      background: none;
      border-bottom: 1px solid var(--color-gray4);
      transition: color .3s var(--easing-cubic1);

      &[aria-expanded="true"] {
        color: var(--color-nazored);
        font-weight: 700;
        border-bottom-color: var(--color-nazored);
      }

      @include minmq($lg) {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        text-align: left;
        padding-top: 16px;
      }

      @include hover {
        color: var(--color-nazored);
      }
    }

    &--contents {
      grid-area: newsconts;
    }

    &--archivelist {
      display: none;

      &[aria-hidden="false"] {
        display: block;
        animation: inviewfadein .4s var(--easing-cubic1) forwards;
      }
    }

  }

  &__faq {
    padding-bottom: 72px;
    background-color: #EEEEF5;

    @include minmq($lg) {
      padding-bottom: 120px;
    }

    .home__container {
      position: relative;
      z-index: 1;

      @include minmq($md) {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 40px;
      }

      @include minmq($lg) {
        gap: 32px;
      }
    }

    .home__hgroup {
      margin-bottom: 32px;

      @include minmq($md) {
        margin-bottom: 0;
      }
    }

    &--nav {
      @include minmq($md) {
        flex: 0 0 auto;
      }
    }

    &--btnlist {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px 24px;

      @include minmq($md) {
        max-width: 610px;
        grid-template-columns: 1fr 1fr;
        justify-content: flex-end;
        margin-left: auto;
      }

      @include minmq($lg) {
        gap: 16px 32px;
      }
    }

    &--btnitem {
      display: flex;
      justify-content: center;
    }

    &--btnlink {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 320px;
      width: 100%;
      height: 55px;
      @include fontsize(16);
      color: var(--color-keynavy);
      font-weight: 500;
      text-align: center;
      background-color: var(--color-btn-secondary);
      border-radius: var(--border-halfcircle);
      border: 2px solid var(--color-btn-line);
      box-shadow: var(--shadow-btn-default);
      transition: color .3s var(--easing-cubic1), box-shadow .15s var(--easing-cubic1), translate .15s var(--easing-cubic1);

      @include hover {
        color: var(--color-nazored);
        translate: 0 2px;
        box-shadow: 0 4px 0 rgba(23 27 60 / .13);
      }

      @include minmq($lg) {
        // max-width: 240px;
      }
    }
  }
}

@keyframes scrolldown {
  0% {
    transform-origin: 0% 0%;
    transform: scale(1, 0);
  }

  50% {
    transform-origin: 0% 0%;
    transform: scale(1, 1);
  }

  50.1% {
    transform-origin: 0% 100%;
    transform: scale(1, 1);
  }

  100% {
    transform-origin: 0% 100%;
    transform: scale(1, 0);
  }
}