@import 'function/var';

.header {
  width: 100%;
  height: var(--header-height);
  background-color: var(--color-bgheader);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  transition: background-color .2s var(--easing-cubic1);

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    height: 100%;

    @include minmq($xs) {
      padding-left: 24px;
    }

    @include minmq($lg) {
      padding-left: 40px;
    }
  }

  &__logo {
    flex: 0 0 auto;
  }

  &__block {
    flex: 0 0 auto;
    display: flex;
    gap: 16px;
    height: 100%;

    @include minmq($lg) {
      gap: 24px;
    }

    &--mypage {
      flex: 0 0 auto;
      align-self: center;
    }

    &--btn {
      padding: 12px 16px;
      font-size: clamp-px(12px, 13px, 14px, $lg);
    }

    &--trigger {
      flex: 0 0 auto;
      width: var(--header-height);
      height: var(--header-height);
      background-color: var(--color-bgnav);
      border-bottom: 1px solid var(--color-white);
      position: relative;

      span,
      &::before,
      &::after {
        display: block;
        content: '';
        width: 26px;
        height: 0;
        border-radius: var(--border-halfcircle);
        border-top: 2px solid var(--color-white);
        position: absolute;
        pointer-events: none;
        transition: opacity .3s var(--easing-cubic1), translate .3s var(--easing-cubic1), rotate .3s var(--easing-cubic1);

        @include minmq($lg) {
          width: 34px;
        }

      }

      span {
        top: 50%;
        left: 50%;
        translate: -50% -50%;
      }

      &::before {
        top: 50%;
        left: 50%;
        translate: -50% calc(-50% - 10px);
      }

      &::after {
        top: 50%;
        left: 50%;
        translate: -50% calc(-50% + 10px);
      }

      // .is-gnavshow & {
      //   height: var(--header-height);
      //   z-index: 20;
      //   position: fixed;
      //   top: 0;
      //   right: 0;

      //   @include minmq($lg) {
      //     position: absolute;
      //     top: 0;
      //     right: 0;
      //   }

      //   span,
      //   &::before,
      //   &::after {
      //     transition: opacity .3s var(--easing-cubic1), translate .3s var(--easing-cubic1), rotate .3s var(--easing-cubic1);
      //   }

      //   span {
      //     opacity: 0;
      //     translate: 0 -50%;
      //   }

      //   &::before {
      //     width: 28px;
      //     rotate: 45deg;
      //     translate: -50% -50%;
      //   }

      //   &::after {
      //     width: 28px;
      //     rotate: -45deg;
      //     translate: -50% -50%;
      //   }

      //   @include minmq($lg) {
      //     span {
      //       width: 34px;
      //       top: 50%;
      //       left: 50%;
      //       opacity: 1;
      //       translate: -50% -50%;
      //     }

      //     &::before {
      //       width: 34px;
      //       top: 50%;
      //       left: 50%;
      //       rotate: 0deg;
      //       translate: -50% calc(-50% - 10px);
      //     }

      //     &::after {
      //       width: 34px;
      //       top: 50%;
      //       left: 50%;
      //       rotate: 0deg;
      //       translate: -50% calc(-50% + 10px);
      //     }
      //   }
      // }
    }
  }
}