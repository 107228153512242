@import 'function/var';

:root {

  // breakpoint
  --breakpoint-sm: #{$sm};
  --breakpoint-md: #{$md};
  --breakpoint-lg: #{$lg};

  // Scroll Header Height
  --header-height: 64px;

  @include minmq($lg) {
    --header-height: 80px;
  }


  // font
  --font-notosansjp: 'Noto Sans JP',
  sans-serif;
  --font-satoshi: 'Satoshi',
  sans-serif;

  // color
  --color-black: #000;
  --color-white: #fff;
  --color-gray1: #343131;
  --color-gray2: #625C5C;
  --color-gray3: #999;
  --color-gray4: #DCD6D6;
  --color-gray5: #F4F4F4;

  --color-keynavy: #171B3C;
  --color-nazored: #DD0000;

  --color-cyan: #EBF0FB;
  --color-breadcrumbs: #EEEEF5;

  // color set
  --color-txtbase: var(--color-gray1);

  --color-txtlink: var(--color-keynavy);
  --color-link-line: var(--color-keynavy);

  --color-bgheader: var(--color-white);
  --color-bgfooter: var(--color-keynavy);
  --color-bgnav: var(--color-keynavy);
  --color-bgbox: var(--color-gray5);

  --color-btn-primary: var(--color-nazored);
  --color-btn-secondary: var(--color-white);
  --color-btn-line: var(--color-keynavy);


  // border
  --border-radius: 16px;
  --border-halfcircle: 50px;
  --border-circle: 50%;

  @include minmq($lg) {
    --border-radius: 24px;
    --border-halfcircle: 80px;
  }


  // shadow
  --shadow-default: #{0 6px 0 rgba(52 49 49 / .20)};
  --shadow-btn-default: #{0 6px 0 rgba(23 27 60 / .13)};

  // easing
  --easing-cubic1: cubic-bezier(0, 0, 0, 1);

}