// /* [ Var ] SASS関数やmixinを定義 */

// --------------------------------------------------
// ブレークポイント
// --------------------------------------------------
$xs: 375px;
$sm: 576px;
$md: 768px;
$lg: 1024px;
$xlg: 1280px;

@mixin minmq($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin maxmq($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

/** -------------------------------- **
	@mixin
*** ----------------------------------------------------------------- ***/
// テキスト サイズ計算
@mixin fontsize($px) {
  font-size: calc((#{$px} / 16) * 1rem);
}

// テキスト カーニング
@mixin kerning($num) {
  letter-spacing: calc((#{$num} / 1000) * 1em);
}

// clamp計算
@function clamp-px($min, $value, $max, $bp) {
  $size_vw: calc(($value / $bp) * 100 * 1vw); // そのビューポイントでの表示サイズ値をvw計算

  @return clamp($min, $size_vw, $max);
}

// vw計算
@function clamp-vw($num, $bp) {
  $vw: calc(($num / $bp) * 100 * 1vw);

  @return $vw;
}

// 表示行数指定
@mixin line-clamp($line: 2) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  overflow: hidden;
}

// Hoverの指定
@mixin hover() {
  &:focus-visible {
    @content;
  }

  @media (any-hover: none) {
    &:active {
      @content;
    }
  }

  @media (any-hover: hover) {
    &:hover {
      @content;
    }
  }

}